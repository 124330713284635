import { TableCell, TableRow } from "@mui/material";
import { Edit, DeleteOutline } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import * as S from "./styled";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAll,
  DeleteRow,
  UpdateValue,
} from "../../../features/PlansReportSlice";
import { getFirebaseDataByCreationDate } from "../../../Services/firebase";
import { deletePlan } from "../../../Services/plans";
import { usePlansTrends } from "../../../Hooks/usePlansTrends";
import {
  selectAllTrendsPrices,
  UpdateTrendsPricesValue,
} from "../../../features/TrendsPricesSlice";
import { LoadingSpinner } from "../../../Components/Loader";
import { Modal } from "../../../Components/Modal";
import { PrimaryButton } from "../../../Components/Button";
import ReactPaginate from "react-paginate";
import { CaretLeft, CaretRight } from "phosphor-react";
import { TABLES } from "../../../enums/tables";

const currentPurchaseTypePlan = {
  complete: "ConnectStore completo",
  basic: "ConnectStore básico",
  "amateur-guide": "ConnectGuide não credenciado",
  "pro-guide": "ConnectGuide credenciado",
  connectsale: "ConnectSale",
  highlight: "Highlight",
  event: "Evento",
  connectwatches: "ConnectWatch",
};

const typeLabel = {
  connectguides: "ConnectGuides",
  connectsales: "ConnectSales",
  connectstores: "ConnectStores",
  connectplaces: "ConnectPlaces",
  connectroutes: "ConnectRoutes",
  connectwatches: "ConnectWatches",
  events: "Eventos",
};

export default function Index({
  editItemPlans,
  editItemTrends,
  onToastOpen,
  orderFilter,
}) {
  const query = useSelector((state) => state?.query?.query_string);
  const queryNum = useSelector((state) => state?.query?.queryByNumber);
  const categoryDataPlans = useSelector(selectAll);

  const categoryDataTrendsPrices = useSelector(selectAllTrendsPrices);
  useEffect(() => {}, [categoryDataTrendsPrices]);
  const dispatch = useDispatch();
  const [filteredPlansData, setFilteredPlansdData] = useState([]);
  const [filteredTrendsData, setFilteredTrendsdData] = useState([]);
  const [
    formattedCategoryDataTrendsPrices,
    setFormattedCategoryDataTrendsPrices,
  ] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentQueryNum, setCurrentQueryNum] = useState(queryNum);
  const [activePage, setActivePage] = useState(0);

  const { showHighlights } = usePlansTrends();

  async function getData() {
    const data = await getFirebaseDataByCreationDate(TABLES.PLANS);
    const dataPlans = data
        .filter((item) => item.nature !== "highlight" && item.active === true)
        .map((item) => ({ ...item, label: typeLabel[item?.collection] }));
    const highlightsPlans = data.filter(
        (item) => item.nature === "highlight" && item.active === true
    );

    dispatch(UpdateTrendsPricesValue(highlightsPlans));
    dispatch(UpdateValue(dataPlans));
}

  useEffect(() => {
    try {
      getData();
    } catch (error) {
      console?.error(error);
    }
  }, [showHighlights]);

  useEffect(() => {
    if (categoryDataPlans?.length > 0) {
      const filtered = categoryDataPlans
        ?.filter((row) => {
          const nameMatch = row?.names?.["pt-BR"]
            ?.toLowerCase()
            ?.includes(query);

          const typeMatch = row?.label?.toLowerCase()?.includes(query);

          const brlMatch = String(
            row?.prices?.BRL?.price_minor_units / 100
          )?.includes(query);

          const usdMatch = String(
            row?.prices?.USD?.price_minor_units / 100
          )?.includes(query);

          const eurMatch = String(
            row?.prices?.EUR?.price_minor_units / 100
          )?.includes(query);

          return nameMatch || typeMatch || brlMatch || usdMatch || eurMatch;
        })
        ?.sort((a, b) => {
          if (orderFilter && orderFilter?.id === "name") {
            return orderFilter?.order === "asc"
              ? b?.names["pt-BR"]?.localeCompare(a?.names["pt-BR"])
              : a?.names["pt-BR"]?.localeCompare(b?.names["pt-BR"]);
          }
          if (orderFilter && orderFilter?.id === "type") {
            return orderFilter?.order === "asc"
              ? b?.label?.localeCompare(a?.label)
              : a?.label?.localeCompare(b?.label);
          }
          if (orderFilter && orderFilter?.id === "brl") {
            return orderFilter?.order === "asc"
              ? b?.prices?.BRL?.price_minor_units / 100 -
                  a?.prices?.BRL?.price_minor_units / 100
              : a?.prices?.BRL?.price_minor_units / 100 -
                  b?.prices?.BRL?.price_minor_units / 100;
          }

          if (orderFilter && orderFilter?.id === "usd") {
            return orderFilter?.order === "asc"
              ? b?.prices?.USD?.price_minor_units / 100 -
                  a?.prices?.USD?.price_minor_units / 100
              : a?.prices?.USD?.price_minor_units / 100 -
                  b?.prices?.USD?.price_minor_units / 100;
          }

          if (orderFilter && orderFilter?.id === "eur") {
            return orderFilter?.order === "asc"
              ? b?.prices?.EUR?.price_minor_units / 100 -
                  a?.prices?.EUR?.price_minor_units / 100
              : a?.prices?.EUR?.price_minor_units / 100 -
                  b?.prices?.EUR?.price_minor_units / 100;
          }

          return 0;
        });
      setFilteredPlansdData(filtered);
    }
  }, [query, orderFilter, categoryDataPlans]);

  useEffect(() => {
    if (formattedCategoryDataTrendsPrices) {
      const filtered = formattedCategoryDataTrendsPrices
        ?.filter((row) => {
          const typeMatch = row?.type?.toLowerCase()?.includes(query);

          const placeMatch = row?.place?.toLowerCase()?.includes(query);

          return typeMatch || placeMatch;
        })
        ?.sort((a, b) => {
          if (orderFilter && orderFilter?.id === "trendsPlace") {
            return orderFilter?.order === "asc"
              ? b?.place?.localeCompare(a?.place)
              : a?.place?.localeCompare(b?.place);
          }
          if (orderFilter && orderFilter?.id === "trendsType") {
            return orderFilter?.order === "asc"
              ? b?.type?.localeCompare(a?.type)
              : a?.type?.localeCompare(b?.type);
          }

          return 0;
        });
      setFilteredTrendsdData(filtered);
    }
  }, [
    query,
    orderFilter,
    categoryDataTrendsPrices,
    formattedCategoryDataTrendsPrices,
  ]);

  useEffect(() => {
    if (categoryDataTrendsPrices?.length === 0) return;
    setFormattedCategoryDataTrendsPrices(
      categoryDataTrendsPrices?.map((item) => {
        let currentPlace = "--";
        if (
          item?.highlight_screens?.includes("home") &&
          !item?.highlight_screens?.includes("explore")
        )
          currentPlace = "Tela principal";
        if (
          item?.highlight_screens?.includes("explore") &&
          !item?.highlight_screens?.includes("home")
        )
          currentPlace = "Tela de categoria";
        if (
          item?.highlight_screens?.includes("explore") &&
          item?.highlight_screens?.includes("home")
        )
          currentPlace = "Tela principal + Categoria";

        return {
          ...item,
          place: currentPlace,
          type: typeLabel[item.collection] ?? "--",
        };
      })
    );
  }, [categoryDataTrendsPrices]);

  useEffect(() => {
    setCurrentPage(0);
    setCurrentQueryNum(queryNum);
    setActivePage(0);
  }, [queryNum, query, showHighlights]);

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);

  const startIndex = currentPage * currentQueryNum;
  const endIndex = startIndex + currentQueryNum;
  const paginatedData = !showHighlights
    ? filteredPlansData?.slice(startIndex, endIndex)
    : filteredTrendsData?.slice(startIndex, endIndex);

  return (
    <>
      {!showHighlights ? (
        <>
          {paginatedData?.map((row) => {
            return (
              <React.Fragment key={row?.id}>
                {/* {row?.id === editRowId ? (
             <EditableRow data={row} setEditId={setEditId} />
           ) : ( */}
                <ReadOnlyRow
                  onToastOpen={onToastOpen}
                  data={row}
                  setEditIdPlans={editItemPlans}
                />
                {/* )} */}
              </React.Fragment>
            );
          })}
          <ReactPaginate
            previousLabel={<CaretLeft size={16} />}
            nextLabel={<CaretRight size={16} />}
            breakLabel={"..."}
            pageCount={
              !showHighlights
                ? Math?.ceil(filteredPlansData?.length / currentQueryNum)
                : Math?.ceil(filteredTrendsData?.length / currentQueryNum)
            }
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(selectedPage) => {
              setActivePage(selectedPage?.selected);
              setCurrentPage(selectedPage?.selected);
            }}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={activePage}
          />
        </>
      ) : (
        <>
          {paginatedData
            // ?.filter((row) => row?.name?.toLowerCase()?.includes(query))
            // ?.slice(0, queryNum)
            ?.map((row) => {
              return (
                <React.Fragment key={row?.id}>
                  {/* {row?.id === editRowId ? (
                <EditableRow data={row} setEditId={setEditId} />
              ) : ( */}
                  <ReadOnlyRow
                    onToastOpen={onToastOpen}
                    data={row}
                    setEditIdTrends={editItemTrends}
                  />
                  {/* )} */}
                </React.Fragment>
              );
            })}
          <ReactPaginate
            previousLabel={<CaretLeft size={16} />}
            nextLabel={<CaretRight size={16} />}
            breakLabel={"..."}
            pageCount={
              !showHighlights
                ? Math?.ceil(filteredPlansData?.length / currentQueryNum)
                : Math?.ceil(filteredTrendsData?.length / currentQueryNum)
            }
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(selectedPage) => {
              setActivePage(selectedPage?.selected);
              setCurrentPage(selectedPage?.selected);
            }}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={activePage}
          />
        </>
      )}
    </>
  );
}

export const ReadOnlyRow = ({
  data: row,
  setEditIdPlans,
  setEditIdTrends,
  onToastOpen,
}) => {
  const dispatch = useDispatch();
  const { showHighlights } = usePlansTrends();
  const [selectedDay, setSelectedDay] = useState(5);
  const [isDeleting, setIsDeleting] = useState(false);
  const [open, setOpen] = useState(false);

  async function handleDeletePlan() {
    try {
      setIsDeleting(true);
      await deletePlan(row);
      dispatch(DeleteRow({ id: row?.id }));
      onToastOpen("Success", "success");
    } catch (error) {
      console?.error(error);
      onToastOpen("Error", "error");
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <>
      <Modal
        open={open}
        onOpenChange={(open) => {
          if (!isDeleting) {
            setOpen(open);
          }
        }}
        title="Atenção!"
        description={
          showHighlights
            ? `Tem certeza que deseja deletar?`
            : `Tem certeza que deseja deletar "${row?.names["pt-BR"]}"?`
        }
      >
        <S.ModalContainer>
          <PrimaryButton onClick={() => setOpen(false)}>Cancelar</PrimaryButton>

          <PrimaryButton
            onClick={handleDeletePlan}
            style={{ marginLeft: "auto" }}
          >
            {isDeleting ? <LoadingSpinner /> : "Confirmar"}
          </PrimaryButton>
        </S.ModalContainer>
      </Modal>
      <TableRow key={row?.id}>
        {!showHighlights ? (
          <>
            <TableCell sx={{ width: "200px" }}>{row?.names["pt-BR"]}</TableCell>
            <TableCell>{currentPurchaseTypePlan[row?.type]}</TableCell>
            <TableCell>
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(row?.prices?.BRL?.price_minor_units / 100 || 0)}
            </TableCell>
            <TableCell>
              {Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(row?.prices?.USD?.price_minor_units / 100 || 0)}
            </TableCell>
            <TableCell>
              {Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(row?.prices?.EUR?.price_minor_units / 100 || 0)}
            </TableCell>
            <TableCell align="right">
              <Edit color="success" onClick={() => setEditIdPlans(row?.id)} />
            </TableCell>
            <TableCell align="right" sx={{ width: "50px" }} colSpan={0}>
              <DeleteOutline color="error" onClick={() => setOpen(true)} />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell>{typeLabel[row?.collection]}</TableCell>
            <TableCell>{row?.place}</TableCell>
            <TableCell>
              <select onChange={(e) => setSelectedDay(e?.target?.value)}>
                {Object.keys(row?.prices_per_day)?.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </TableCell>

            <>
              <TableCell>
                R$
                {row?.prices_per_day?.[selectedDay]?.currency?.BRL
                  ?.price_minor_units / 100 || "0"}
              </TableCell>
              <TableCell>
                $
                {row?.prices_per_day?.[selectedDay]?.currency?.USD
                  ?.price_minor_units / 100 || "0"}
              </TableCell>
              <TableCell>
                €
                {row?.prices_per_day?.[selectedDay]?.currency?.EUR
                  ?.price_minor_units / 100 || "0"}
              </TableCell>
            </>

            <TableCell align="right">
              <Edit color="success" onClick={() => setEditIdTrends(row?.id)} />
            </TableCell>
          </>
        )}
      </TableRow>
    </>
  );
};
