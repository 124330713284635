import styled from "styled-components";
import React from "react";
import { Dialog, Container } from "@mui/material";
import { PrimaryButton } from "../Button";
import { BR, CN, ES, FR, IT, US } from "country-flag-icons/react/3x2";

//AppContainer
export const AppContainer = styled(Container)`
  background-color: ${({ theme }) => theme.colors.grey};
  max-width: initial !important;
  padding-inline: 2rem !important;
  min-height: calc(100vh - 103.19px);
  padding-bottom: 1rem;
`;

//LanguagesContainer
export const LanguagesContainer = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin-top: -1rem;

  li {
    display: flex;
    align-items: center;
    gap: 4px;

    span {
      width: 1rem;
      height: 1rem;
      padding-top: 0px;

      p {
        font-size: 1.3rem;
      }
    }
  }

  svg {
    width: 100%;
    height: 100%;
    cursor: default;
  }
`;

//InputGroup
export const InputGroup = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 5px 10px;

  /* hidden the arrow from input number
  
  Chrome, Safari, Edge, Opera */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  //label inside
  label {
    position: absolute;
    top: -10px;
    background-color: white;
    padding: 0 5px;
    font-size: 0.8rem;
  }

  div {
    height: 30px;
    max-height: 30px;
    min-height: 10px;
    border: 1px solid transparent !important;
    outline: none !important;
  }
`;

//TILTLE
export const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: medium;
`;
//InputGroup type 2
export const InputGroupExtended = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2px 10px;

  //label inside
  label {
    position: absolute;
    top: -10px;
    background-color: white;
    padding: 0 5px;
    font-size: 0.8rem;
  }

  div {
    height: 30px;
    max-height: 30px;
    min-height: 10px;
    border: 1px solid transparent !important;
    outline: none !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

//TextArea
export const TextAreaGroup = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 5px 10px;
  margin-top: 5px;
  //label inside
  label {
    position: absolute;
    top: -10px;
    background-color: white;
    padding: 0 5px;
  }

  select {
    margin-top: 5px;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  //textarea
  textarea {
    border: 1px solid transparent;
    outline: none;
    padding-top: 10px;
    background-color: transparent;
    max-height: 150px;
  }
`;

/****
 * Overlay Styled Component
 */
export const OverlayContainer = styled.div`
  background-color: #000000af;
  height: 100vh;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  display: grid;
  justify-content: end;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;
//Overlay Component Child
export const OverlayChild = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 360px;
  max-width: 400px;
  min-width: 300px;
  box-shadow: 1px 1px 5px 5px #00000028;
  display: flex;
  flex-direction: column;
  padding: 0px;
  overflow-y: auto;
  padding-bottom: 1rem;
  min-height: 100vh;
`;

//Overlay Component Header
export const OverlayHeader = styled.div`
  padding: 15px 10px;
  border: 1px solid transparent;
  border-bottom-color: #151515;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    font-size: 1.1rem;
    align-self: center;
    justify-self: center;
    padding-right: 4rem;
  }

  //svg
  svg {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 50px;
    align-self: flex-start;
    justify-self: flex-start;
  }
`;

//Overlay Component Body
export const OverlayBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 25px;
    font-size: 0.9rem;
    padding-bottom: 1rem;

    button {
      align-self: center;
      justify-self: center;

      &[type="submit"]:hover {
        background-color: ${({ theme }) => theme.colors.secondary}!important;
        color: ${({ theme }) => theme.colors.primary}!important;
      }
    }
  }
`;

export const ShowTotalWrapper = styled.div`
  text-align: center;
  padding: 0.5rem 1rem;
  box-shadow: 1px 1px 10px 2px #d8d6d695;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;

  h4 {
    font-size: 1.3rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }
  h5 {
    word-break: keep-all;
    word-wrap: normal;
    font-weight: 400;
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
  }
`;

//Display Total
export function ShowTotal({ total, moeda }) {
  const displayTotal = isNaN(Number(String(total).replace(/[^\d.-]/g, "")))
    ? "--"
    : Number(String(total).replace(/[^\d.-]/g, ""));

  let formattedTotal;

  // if (moeda === "BRL") {
  // if (String(total).includes("€")) {
  //   formattedTotal = displayTotal.toLocaleString("de-DE", { style: "currency", currency: "EUR" });
  // } else if (String(total).includes("$")) {
  //   formattedTotal = displayTotal.toLocaleString("en-US", { style: "currency", currency: "USD" });
  // } else if (String(total).includes("R$")) {
  //   formattedTotal = displayTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
  // } else {
  //   formattedTotal = displayTotal;
  // }
  // }
  
  formattedTotal = displayTotal;
  if (moeda) {
    const numericTotal = Number(String(total).replace(/[^\d.-]/g, ""));
    if (moeda === "BRL") {
      formattedTotal = numericTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
    } else if (moeda === "USD") {
      formattedTotal = numericTotal.toLocaleString("en-US", { style: "currency", currency: "USD" });
    } else if (moeda === "EUR") {
      formattedTotal = numericTotal.toLocaleString("de-DE", { style: "currency", currency: "EUR" });
    } else {
      formattedTotal = numericTotal.toLocaleString("en-US", { style: "currency", currency: "USD" });
    }
  }

  return (
    <>
      <ShowTotalWrapper>
        <h4>Total</h4>
        <h5>{formattedTotal}</h5>
      </ShowTotalWrapper>
    </>
  );
}

/***
 * Modal
 */

export const Modal = styled(Dialog)`
  p {
    padding: 0;
    margin: 0;
  }

  span {
    font-weight: bold;
  }

  strong {
    cursor: pointer;
    font-variant: small-caps;

    &:nth-child(1) {
      :hover {
        color: ${({ theme }) => theme.colors.green};
      }
    }

    &:nth-child(2) {
      :hover {
        color: #910404;
      }
    }
  }
`;

//Display Address
export const Address = ({ address }) => {
  return (
    <div>
      <h5 style={{ margin: "5px 0 0 0" }}>Endereço</h5>

      <p
        style={{
          margin: "5px 0 0 0",
          display: "grid",
          gap: "5px",
          color: "#949494",
          fontSize: ".8rem",
        }}
      >
        {address.split(",").map((item, index) => (
          <span key={index}>{item}</span>
        ))}
      </p>
    </div>
  );
};

//Display Address
export const ComplexAddress = ({ address }) => {
  return (
    <div>
      <h5 style={{ margin: "5px 0 0 0" }}>Endereço</h5>

      <p
        style={{
          margin: "5px 0 0 0",
          display: "grid",
          gap: "5px",
          color: "#949494",
          fontSize: ".8rem",
        }}
      >
        <span>{address.cep}</span>
        <span>
          {address.street}, {address.number}, {address.complement}
        </span>
        <span>
          {address.country}, {address.city}
        </span>
      </p>
    </div>
  );
};

//Album Images
export const ImagesAlbum = ({ photos = [], openPreview, title = "Fotos" }) => {
  return (
    <div>
      <div>
        <Title style={{ margin: "5px 0 0 0" }}>{title}</Title>
        <span style={{ color: "#949494", fontSize: ".8rem" }}>
          Clique nas imagens para expandir
        </span>
      </div>

      <div
        style={{
          width: "100%",
          display: "grid",
          gap: "4px",
          gridTemplateColumns: "repeat(3,1fr)",
        }}
      >
        {photos.map((image, index) => (
          <img
            key={index}
            src={image}
            alt="previewImage"
            style={{ width: "100%" }}
            onClick={() => openPreview(image)}
          />
        ))}
      </div>
    </div>
  );
};

//Description

export const Description = ({ desc }) => {
  return (
    <div>
      <Title>Descrição</Title>
      <p style={{ margin: "5px 0 0 0", color: "#949494", fontSize: ".7rem" }}>
        {desc}
      </p>
    </div>
  );
};

//Action Buttons
export const ActionButtons = ({
  type,
  approve,
  reprove,
  id,
  activeTitle = "Aprovar",
  blockTitle = "Reprovar",
  onToastOpen,
  ...rest
}) => {
  /**
   * In this component we declare Approve and Disapprove Functionality
   * @params (__active , _block, __id)
   * */

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
        }}
        {...rest}
      >
        {activeTitle && (
          <PrimaryButton
            style={{ backgroundColor: "#079784" }}
            onClick={() => {
              approve({ id, type });
            }}
          >
            {activeTitle}
          </PrimaryButton>
        )}
        {blockTitle && (
          <PrimaryButton
            style={{ backgroundColor: "#ec0202" }}
            onClick={() => reprove({ id, type })}
          >
            {blockTitle}
          </PrimaryButton>
        )}
      </div>
    </>
  );
};

//ContactInfo
export const ContactInfo = ({ contact }) => {
  const title = Object.freeze({
    email: "Email",
    tel: "Telefone",
  });
  return (
    <>
      <div style={{ display: "grid", gap: "7px" }}>
        <Title>Dados Cadastrais</Title>
        <ul style={{ color: "#949494", fontSize: ".8rem" }}>
          <li>{contact?.phone && `${title.tel}: ${contact?.phone}`}</li>
          <li>{contact?.email && `${title.email}: ${contact?.email}`}</li>
        </ul>
      </div>
    </>
  );
};

export const GuideInfo = ({ info }) => {
  const title = Object.freeze({
    fiscal_code: "Código fiscal",
    instituition: "Instituição",
  });
  return (
    <>
      <div style={{ display: "grid", gap: "7px" }}>
        <Title>Informações adicionais</Title>
        <ul style={{ color: "#949494", fontSize: ".8rem" }}>
          <li>{`${title.fiscal_code}: ${info.fiscal_code || "--"}`}</li>
          <li>{`${title.instituition}: ${info.instituition || "--"}`}</li>
        </ul>
      </div>
    </>
  );
};

function spokenLanguagesIcons(languages = []) {
  return languages
    .map((language) => {
      if (language === "pt-BR") {
        return {
          language: language.toUpperCase(),
          Icon: BR,
        };
      }
      if (language === "en-US") {
        return {
          language: language.toUpperCase(),
          Icon: US,
        };
      }
      if (language === "es") {
        return {
          language: language.toUpperCase(),
          Icon: ES,
        };
      }
      if (language === "fr") {
        return {
          language: language.toUpperCase(),
          Icon: FR,
        };
      }
      if (language === "it") {
        return {
          language: language.toUpperCase(),
          Icon: IT,
        };
      }
      if (language === "zh") {
        return {
          language: language.toUpperCase(),
          Icon: CN,
        };
      }
      return {
        language: "",
        Icon: US,
      };
    })
    .filter((item) => item.language);
}

export const LanguagesInfo = ({ languages }) => {
  return (
    <>
      <Title>Linguas</Title>
      <LanguagesContainer>
        {spokenLanguagesIcons(
          languages?.map((language) =>
            language.length > 2
              ? `${language.split("-")[0].toLowerCase()}-${language.slice(
                  3,
                  7
                )}`
              : language.split("-")[0].toLowerCase().slice(0, 2)
          )
        ).map(({ language, Icon }) => (
          <li key={language}>
            <span>
              <Icon />
            </span>
            <p>{language}</p>
          </li>
        ))}
      </LanguagesContainer>
    </>
  );
};

//Plano Vigente
export const Plan = ({ plans }) => {
  return (
    <div style={{ display: "grid", gap: "7px" }}>
      <Title>Plano Vigente</Title>
      {plans.map((item, index) => (
        <ul style={{ color: "#949494", fontSize: ".8rem" }} key={index}>
          <li>{item.title}</li>
          <li>{item.price}</li>
        </ul>
      ))}
    </div>
  );
};

//Spoken Languages
export const SpokenLang = ({ languages }) => {
  return (
    <>
      <div style={{ display: "grid", gap: "7px" }}>
        <Title>Idiomas Falados</Title>
        <ul style={{ color: "#949494", fontSize: ".8rem" }}>
          {languages.split(",").map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    </>
  );
};

export const TitleWithTwoDots = ({ title, description, under, ...rest }) => {
  return (
    <div
      {...rest}
      style={{
        display: "flex",
        gap: "7px",
        alignItems: under ? "flex-start" : "center",
        justifyContent: "center",
        flexDirection: under ? "column" : "row",
        marginTop: under ? "1rem" : "",
      }}
    >
      <Title>{title}:</Title>
      <p style={{ color: "#949494", fontSize: "1rem", margin: "0" }}>
        {description}
      </p>
    </div>
  );
};

/*****
 * Styled Components
 */

export const StyledProfile = styled.div`
  display: flex;
  justify-content: space-between;

  ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 0.8rem;
  }
`;
