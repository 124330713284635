import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { Switch, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAll,
  UpdateValue,
} from "../../../features/TuristicPointsReportSlice";
import { getFirebaseDataByCreationDate } from "../../../Services/firebase";
import { dateFormatter } from "../../../utils/formatter";
import ReactPaginate from "react-paginate";
import { CaretLeft, CaretRight } from "phosphor-react";
import { getNumberOfItinerariesWithStopId } from "../../../Services/itinerary";
import BaseFilter from "../../../Components/BaseFilter/BaseFilter";
import dayjs from "dayjs";
import { showOnlyFirstSixDigits } from "../../../utils/showOnlyFirstSixDigits";
import { TABLES } from "../../../enums/tables";

/****
 * BODY DATA FOR Category TABLE TO MAKE OUR TABLE REUSABLE
 */

export default function Index({
  OpenModal,
  OpenDetails,
  orderFilter,
  setDataQuantity,
  secondaryFilter,
  dateFilter,
  modalNameFilter,
  setModalNameFilter,
  modalItineraryFilter,
  setModalItineraryFilter,
  modalCategoryFilter,
  setModalCategoryFilter,
  modalCountryFilter,
  setModalCountryFilter,
  modalCityFilter,
  setModalCityFilter,
  modalStateFilter,
  setModalStateFilter,
  setFilteredDataLength,
}) {
  //get QUERY_BYSTRING AND QUERY_BYNUM
  const query = useSelector((state) => state?.query?.query_string);
  const queryNum = useSelector((state) => state?.query?.queryByNumber);
  const categoryData = useSelector(selectAll);
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentQueryNum, setCurrentQueryNum] = useState(queryNum);
  const [activePage, setActivePage] = useState(0);
  const [modalNameFilterSearch, setModalNameFilterSearch] = useState([]);
  const [modalItineraryFilterSearch, setModalItineraryFilterSearch] = useState(
    []
  );
  const [modalCountryFilterSearch, setModalCountryFilterSearch] = useState([]);
  const [modalCityFilterSearch, setModalCityFilterSearch] = useState([]);
  const [modalStateFilterSearch, setModalStateFilterSearch] = useState([]);

  async function getDataFromFirebase(collection) {
    const data = await getFirebaseDataByCreationDate(collection);
    const promises = data?.map(async (item) => {
      const size = await getNumberOfItinerariesWithStopId(item?.id);
      return {
        ...item,
        itineraryCount: size,
      };
    });
    const itineraries = await Promise?.all(promises);
    dispatch(UpdateValue(itineraries));
  }

  useEffect(() => {
    try {
      getDataFromFirebase(TABLES.CONNECTPLACES);
    } catch (error) {
      console?.error(error);
    }
  }, []);

  useEffect(() => {
    if (categoryData) setDataQuantity(categoryData?.length);
  }, [categoryData]);

  useEffect(() => {
    if (categoryData?.length > 0) {
      const toDate = dayjs(dateFilter?.to);
      const fromDate = dayjs(dateFilter?.from);

      const filtered = categoryData
        ?.filter((row) => {
          let filterNameMatch = true;
          let filterItineraryMatch = true;
          let filterStateMatch = true;
          let filterCountryMatch = true;
          let filterCityMatch = true;

          if (modalNameFilterSearch?.length > 0) {
            const matchingItems = modalNameFilterSearch?.filter(
              (filterItem) =>
                filterItem?.name?.toLowerCase()?.trim() ===
                row?.name?.toLowerCase()?.trim()
            );
            filterNameMatch = matchingItems?.length > 0;
          }
          if (modalItineraryFilterSearch?.length > 0) {
            const matchingItems = modalItineraryFilterSearch?.filter(
              (filterItem) =>
                String(filterItem?.itineraryCount)?.toLowerCase()?.trim() ===
                String(row?.itineraryCount)?.toLowerCase()?.trim()
            );
            filterItineraryMatch = matchingItems?.length > 0;
          }

          if (modalCountryFilterSearch?.length > 0) {
            const matchingItems = modalCountryFilterSearch?.filter(
              (filterItem) =>
                filterItem?.country?.toLowerCase()?.trim() ===
                  row?.address?.country?.toLowerCase()?.trim() ||
                (filterItem?.country === "Sem pais" &&
                  row?.address?.country === "") ||
                (filterItem?.country === "Sem pais" && !row?.address?.country)
            );
            filterCountryMatch = matchingItems?.length > 0;
          }
          if (modalCityFilterSearch?.length > 0) {
            const matchingItems = modalCityFilterSearch?.filter(
              (filterItem) =>
                filterItem?.city?.toLowerCase()?.trim() ===
                  row?.address?.city?.toLowerCase()?.trim() ||
                (filterItem?.city === "Sem cidade" &&
                  row?.address?.city === "") ||
                (filterItem?.city === "Sem cidade" && !row?.address?.city)
            );
            filterCityMatch = matchingItems?.length > 0;
          }

          if (modalStateFilterSearch?.length > 0) {
            const matchingItems = modalStateFilterSearch?.filter(
              (filterItem) =>
                filterItem?.region?.toLowerCase()?.trim() ===
                  row?.address?.region?.toLowerCase()?.trim() ||
                (filterItem?.region === "Sem estado" &&
                  row?.address?.region === "") ||
                (filterItem?.region === "Sem estado" && !row?.address?.region)
            );
            filterStateMatch = matchingItems?.length > 0;
          }

          /*  */
          const nameMatch = row?.name?.toLowerCase()?.includes(query);

          const countryMatch = row?.address?.country
            ?.toLowerCase()
            ?.includes(query);

          const cityMatch = row?.address?.city?.toLowerCase()?.includes(query);

          const stateMatch =
            row?.address?.region &&
            row?.address?.region?.toLowerCase()?.includes(query);

          const codeMatch = row?.id?.toLowerCase()?.includes(query);

          return (
            (nameMatch ||
              codeMatch ||
              countryMatch ||
              cityMatch ||
              stateMatch) &&
            filterNameMatch &&
            filterItineraryMatch &&
            filterStateMatch &&
            filterCityMatch &&
            filterCountryMatch &&
            (dateFilter
              ? dayjs(row?.created_at)?.isAfter(fromDate) &&
                dayjs(row?.created_at)?.isBefore(toDate?.add(1, "day"))
              : true)
          );
        })
        ?.sort((a, b) => {
          if (orderFilter && orderFilter?.id === "name") {
            return orderFilter?.order === "asc"
              ? b?.name?.localeCompare(a?.name)
              : a?.name?.localeCompare(b?.name);
          }
          if (orderFilter && orderFilter?.id === "itineraryCount") {
            return orderFilter?.order === "asc"
              ? b?.itineraryCount - a?.itineraryCount
              : a?.itineraryCount - b?.itineraryCount;
          }
          if (orderFilter && orderFilter?.id === "city") {
            return orderFilter?.order === "asc"
              ? b?.address?.city?.localeCompare(a?.address?.city)
              : a?.address?.city?.localeCompare(b?.address?.city);
          }
          if (orderFilter && orderFilter?.id === "country") {
            return orderFilter?.order === "asc"
              ? b?.address?.country?.localeCompare(a?.address?.country)
              : a?.address?.country?.localeCompare(b?.address?.country);
          }
          if (orderFilter && orderFilter?.id === "state") {
            return orderFilter?.order === "asc"
              ? b?.address?.region &&
                  b?.address?.region?.localeCompare(
                    a?.address?.region && a?.address?.region
                  )
              : a?.address?.region &&
                  a?.address?.region?.localeCompare(
                    b?.address?.region && b?.address?.region
                  );
          }
          if (orderFilter && orderFilter?.id === "date") {
            return orderFilter?.order === "asc"
              ? b?.created_at?.localeCompare(a?.created_at)
              : a?.created_at?.localeCompare(b?.created_at);
          }
          return 0;
        });

    setFilteredDataLength(filtered.length);
    setFilteredData(filtered);
    }
  }, [
    query,
    orderFilter,
    dateFilter,
    categoryData,
    modalNameFilterSearch,
    modalItineraryFilterSearch,
    modalCountryFilterSearch,
    modalCityFilterSearch,
    modalStateFilterSearch,
    setFilteredDataLength,
  ]);

  useEffect(() => {
    setCurrentPage(0);
    setCurrentQueryNum(queryNum);
    setActivePage(0);
  }, [
    queryNum,
    dateFilter,
    query,
    categoryData,
    modalNameFilterSearch,
    modalItineraryFilterSearch,
    modalCountryFilterSearch,
    modalCityFilterSearch,
    modalStateFilterSearch,
  ]);

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);

  if (categoryData?.length > 0) {
    const startIndex = currentPage * currentQueryNum;
    const endIndex = startIndex + currentQueryNum;
    const paginatedData = filteredData?.slice(startIndex, endIndex);

    return (
      <>
        {paginatedData?.map((row) => {
          return (
            <TableRow hover key={row?.id}>
              <TableCell sx={{ width: "20px" }}>
                {showOnlyFirstSixDigits(row?.id)}
              </TableCell>
              <TableCell sx={{ width: "300px" }}>{row?.name}</TableCell>
              <TableCell sx={{ width: "300px" }}>
                {row?.itineraryCount}
              </TableCell>
              <TableCell sx={{ width: "300px" }}>
                {row?.address?.city || "--"}
              </TableCell>
              <TableCell sx={{ width: "300px" }}>
                {row?.address?.region || "--"}
              </TableCell>
              <TableCell sx={{ width: "300px" }}>
                {row?.address?.country || "--"}
              </TableCell>
              <TableCell>
                {dayjs(row?.created_at)?.format("DD/MM/YYYY")}
              </TableCell>
              <TableCell
                sx={{ width: "70px", color: "#006875", cursor: "pointer" }}
                align="center"
              >
                <RemoveRedEyeOutlined onClick={() => OpenDetails(row?.id)} />
              </TableCell>
              <TableCell sx={{ width: "50px" }} align="left">
                <Switch
                  checked={!row?.active}
                  color="success"
                  onChange={() => OpenModal(!row?.active, row?.id, row?.name)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </TableCell>
            </TableRow>
          );
        })}

        <ReactPaginate
          previousLabel={<CaretLeft size={16} />}
          nextLabel={<CaretRight size={16} />}
          breakLabel={"..."}
          pageCount={Math?.ceil(filteredData?.length / currentQueryNum)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(selectedPage) => {
            setActivePage(selectedPage?.selected);
            setCurrentPage(selectedPage?.selected);
          }}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={activePage}
        />

        {modalNameFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalNameFilterSearch}
            setModalFilterSearch={setModalNameFilterSearch}
            setModalFilter={setModalNameFilter}
            data={[
              ...new Set(categoryData?.map((item) => item?.name?.trim())),
            ]?.map((item) => ({
              name: item,
            }))}
            labelPath="name"
            label="nome"
          />
        )}

        {modalItineraryFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalItineraryFilterSearch}
            setModalFilterSearch={setModalItineraryFilterSearch}
            setModalFilter={setModalItineraryFilter}
            data={[
              ...new Set(categoryData?.map((item) => item?.itineraryCount)),
            ]?.map((item) => ({ itineraryCount: String(item) }))}
            labelPath="itineraryCount"
            label="quantidade de ConnectRoutes"
          />
        )}

        {modalCountryFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalCountryFilterSearch}
            setModalFilterSearch={setModalCountryFilterSearch}
            setModalFilter={setModalCountryFilter}
            data={[
              ...new Set(
                categoryData?.map(
                  (item) =>
                    (item?.address?.country &&
                      item?.address?.country?.trim()) ||
                    "Sem pais"
                )
              ),
            ]?.map((item) => ({ country: item }))}
            labelPath="country"
            label="pais"
          />
        )}

        {modalCityFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalCityFilterSearch}
            setModalFilterSearch={setModalCityFilterSearch}
            setModalFilter={setModalCityFilter}
            data={[
              ...new Set(
                categoryData?.map(
                  (item) =>
                    (item?.address?.city && item?.address?.city?.trim()) ||
                    "Sem cidade"
                )
              ),
            ]?.map((item) => ({ city: item }))}
            labelPath="city"
            label="cidade"
          />
        )}

        {modalStateFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalStateFilterSearch}
            setModalFilterSearch={setModalStateFilterSearch}
            setModalFilter={setModalStateFilter}
            data={[
              ...new Set(
                categoryData?.map(
                  (item) =>
                    (item?.address?.region && item?.address?.region?.trim()) ||
                    "Sem estado"
                )
              ),
            ]?.map((item) => ({ region: item }))}
            labelPath="region"
            label="estado"
          />
        )}
      </>
    );
  }
}
