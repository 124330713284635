import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore'
import React, { useState, createContext, useEffect } from 'react'
import { auth, db } from '../firebase'

export const AuthContext = createContext({})

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [currentUserData, setCurrentUserData] = useState(null)
  const [loading, setLoading] = useState(true)

  const value = {
    currentUser,
    currentUserData,
    login,
    signup,
    logout,
    forgotPassword,
    updateEmail,
    updatePassword,
    updateUserInfo
  }

  async function login(email, password) {
    try {
      // Primeiro verificar se o usuário existe
      const methods = await auth.fetchSignInMethodsForEmail(email);
      
      if (methods.length === 0) {
        throw new Error('auth/user-not-found');
      }
      
      const result = await auth.signInWithEmailAndPassword(email, password);
      
      // Buscar dados do usuário imediatamente após o login
      await getUserData(result.user.uid);
      
      return result;
    } catch (error) {
      console.error('AuthContext: Erro no login:', error);
      throw error;
    }
  }

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  function logout() {
    return auth.signOut()
  }

  async function forgotPassword(email) {
    try {
      await auth.sendPasswordResetEmail(email);
      return true;
    } catch (error) {
      console.error('AuthContext: Erro ao enviar email de reset:', error);
      throw error;
    }
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  async function updateUserInfo(options) {
    const userDoc = doc(db, 'users', currentUser.uid)
    return updateDoc(userDoc, { ...options })
  }

  async function getUserData(id) {
    try {
      const userDoc = await getDoc(doc(db, 'users', id));
      
      if (!userDoc.exists()) {
        // Se o documento não existir, vamos criar um novo com role "admin"
        const userData = {
          role: 'admin',
          email: currentUser?.email,
          createdAt: new Date().toISOString()
        };
        
        await setDoc(doc(db, 'users', id), userData);
        setCurrentUserData(userData);
        return userData;
      }
      
      const userData = userDoc.data();
      setCurrentUserData(userData);
      return userData;
    } catch (error) {
      console.error('Erro ao buscar/criar dados do usuário:', error);
      throw error;
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        await getUserData(user.uid);
      } else {
        setCurrentUserData(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
