import { TableCell, TableRow, Select, MenuItem } from "@mui/material";
import { Edit, DeleteOutline } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import * as S from "./styles";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAll as itinerarySelectAll,
  DeleteRow,
  UpdateRow,
  UpdateValue as itineraryUpdateValue,
} from "../../../features/ItineraryReportSlice";
import {
  selectAll as categorySelectAll,
  UpdateValue as categoryUpdateValue,
} from "../../../features/CategorySlice";
import {
  selectAll as touristSpotSelectAll,
  UpdateValue as touristSpotUpdateValue,
} from "../../../features/TuristicPointsReportSlice";
import { getFirebaseDataByCreationDate } from "../../../Services/firebase";
import { dateInputFormatter } from "../../../utils/formatter";
import * as itinerary from "../../../Services/itinerary";
import ReactPaginate from "react-paginate";
import { CaretLeft, CaretRight } from "phosphor-react";
import TypePill from "../../../Components/TypePill";
import BaseFilter from "../../../Components/BaseFilter/BaseFilter";
import dayjs from "dayjs";
import { showOnlyFirstSixDigits } from "../../../utils/showOnlyFirstSixDigits";
import { TABLES } from "../../../enums/tables";

export default function Index({
  onToastOpen,
  secondaryFilter,
  orderFilter,
  dateFilter,
  modalNameFilter,
  setModalNameFilter,
  modalItineraryFilter,
  setModalItineraryFilter,
  modalCategoryFilter,
  setModalCategoryFilter,
  modalCountryFilter,
  setModalCountryFilter,
  modalCityFilter,
  setModalCityFilter,
  setFilteredDataLength,
}) {
  const query = useSelector((state) => state?.query?.query_string);
  const queryNum = useSelector((state) => state?.query?.queryByNumber);
  const itineraryReportData = useSelector(itinerarySelectAll);
  const categoryData = useSelector(categorySelectAll);
  const touristSpotData = useSelector(touristSpotSelectAll);
  const [editRowId, setEditId] = useState(null);
  const dispatch = useDispatch();
  const [formattedItinerary, setFormattedItinerary] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentQueryNum, setCurrentQueryNum] = useState(queryNum);
  const [activePage, setActivePage] = useState(0);
  const [modalNameFilterSearch, setModalNameFilterSearch] = useState([]);
  const [modalItineraryFilterSearch, setModalItineraryFilterSearch] = useState(
    []
  );
  const [modalCategoryFilterSearch, setModalCategoryFilterSearch] = useState(
    []
  );
  const [modalCountryFilterSearch, setModalCountryFilterSearch] = useState([]);
  const [modalCityFilterSearch, setModalCityFilterSearch] = useState([]);

  async function getDataFromFirebase() {
    const itineraryData = await getFirebaseDataByCreationDate(
      TABLES.CONNECTROUTES
    );
    dispatch(itineraryUpdateValue(itineraryData));

    const touristSpotData = await getFirebaseDataByCreationDate(
      TABLES.CONNECTPLACES
    );
    dispatch(touristSpotUpdateValue(touristSpotData));
  }

  useEffect(() => {
    if (touristSpotData?.length > 0)
      setFormattedItinerary(
        itineraryReportData?.map((itinerary) => {
          const stopsAddresses = itinerary?.stops?.map((stop) =>
            touristSpotData?.find((touristSpot) => touristSpot?.id === stop?.id)
          );

          return {
            ...itinerary,
            stopsAddresses,
          };
        })
      );
  }, [itineraryReportData, touristSpotData]);

  useEffect(() => {
    try {
      getDataFromFirebase();
    } catch (error) {
      console?.error(error);
    }
  }, []);

  useEffect(() => {
    if (formattedItinerary) {
      const toDate = dayjs(dateFilter?.to);
      const fromDate = dayjs(dateFilter?.from);

      const filtered = formattedItinerary
        ?.filter((row) => {
          let filterNameMatch = true;
          let filterItineraryMatch = true;
          let filterCategoryMatch = true;
          let filterCountryMatch = true;
          let filterCityMatch = true;

          let nameMatch = row?.name?.toLowerCase()?.includes(query);
          let countryMatch = row?.stopsAddresses[0]?.address?.country
            ?.toLowerCase()
            ?.includes(query);

          let idMatch = String(row?.id)?.toLowerCase()?.includes(query);

          let cityMatch = row?.stopsAddresses[0]?.address?.city
            ?.toLowerCase()
            ?.includes(query);

          let categoryMatch = String(row?.category?.names["pt-BR"])
            ?.toLowerCase()
            ?.includes(query);

          let stablishementById = true;
          if (secondaryFilter?.establishmentId) {
            const stopsCount = row?.stops?.filter(
              (stop) => stop?.id === secondaryFilter?.establishmentId
            );
            stablishementById = stopsCount?.length > 0 ? true : false;
          }
          /*  */
          let touristSpotById = true;
          if (secondaryFilter?.touristSpotId) {
            const stopsCount = row?.stops?.filter(
              (stop) => stop?.id === secondaryFilter?.touristSpotId
            );
            touristSpotById = stopsCount?.length > 0 ? true : false;
          }

          if (modalNameFilterSearch?.length > 0) {
            const matchingItems = modalNameFilterSearch?.filter(
              (filterItem) =>
                filterItem?.name?.toLowerCase()?.trim() ===
                row?.name?.toLowerCase()?.trim()
            );
            filterNameMatch = matchingItems?.length > 0;
          }

          if (modalItineraryFilterSearch?.length > 0) {
            const matchingItems = modalItineraryFilterSearch?.filter(
              (filterItem) =>
                filterItem?.itinerary?.toLowerCase()?.trim() ===
                row?.itineraryCount?.toLowerCase()?.trim()
            );
            filterItineraryMatch = matchingItems?.length > 0;
          }

          if (modalCategoryFilterSearch?.length > 0) {
            const matchingItems = modalCategoryFilterSearch?.filter(
              (filterItem) =>
                filterItem?.category?.toLowerCase()?.trim() ===
                row?.category?.names["pt-BR"]?.toLowerCase()?.trim()
            );
            filterCategoryMatch = matchingItems?.length > 0;
          }

          if (modalCountryFilterSearch?.length > 0) {
            const matchingItems = modalCountryFilterSearch?.filter(
              (filterItem) =>
                filterItem?.country?.toLowerCase()?.trim() ===
                  row?.stopsAddresses[0]?.address?.country
                    ?.toLowerCase()
                    ?.trim() ||
                (filterItem?.country === "Sem pais" &&
                  row?.address?.country === "") ||
                (filterItem?.country === "Sem pais" &&
                  !row?.stopsAddresses[0]?.address?.country
                    ?.toLowerCase()
                    ?.trim())
            );
            filterCountryMatch = matchingItems?.length > 0;
          }

          if (modalCityFilterSearch?.length > 0) {
            const matchingItems = modalCityFilterSearch?.filter(
              (filterItem) =>
                filterItem?.city?.toLowerCase()?.trim() ===
                  row?.stopsAddresses[0]?.address?.city
                    ?.toLowerCase()
                    ?.trim() ||
                (filterItem?.city === "Sem cidade" &&
                  row?.address?.city === "") ||
                (filterItem?.city === "Sem cidade" &&
                  !row?.stopsAddresses[0]?.address?.city?.toLowerCase()?.trim())
            );
            filterCityMatch = matchingItems?.length > 0;
          }

          return (
            (nameMatch ||
              idMatch ||
              categoryMatch ||
              countryMatch ||
              cityMatch) &&
            filterNameMatch &&
            filterItineraryMatch &&
            filterCategoryMatch &&
            filterCountryMatch &&
            filterCityMatch &&
            stablishementById &&
            touristSpotById &&
            (dateFilter
              ? dayjs(row?.createdAt)?.isAfter(fromDate) &&
                dayjs(row?.createdAt)?.isBefore(toDate?.add(1, "day"))
              : true)
          );
        })
        ?.sort((a, b) => {
          if (orderFilter) {
            let aKey, bKey;

            switch (orderFilter?.id) {
              case "name":
                aKey = a?.name;
                bKey = b?.name;
                break;
              case "city":
                aKey = a?.stopsAddresses[0]?.address?.city;
                bKey = b?.stopsAddresses[0]?.address?.city;
                break;
              case "country":
                aKey = a?.stopsAddresses[0]?.address?.country;
                bKey = b?.stopsAddresses[0]?.address?.country;
                break;
              case "date":
                aKey = a?.createdAt;
                bKey = b?.createdAt;
                break;
              default:
                return 0;
            }

            return orderFilter?.order === "asc"
              ? bKey?.localeCompare(aKey)
              : aKey?.localeCompare(bKey);
          }

          return 0;
        });

    setFilteredDataLength(filtered.length);
    setFilteredData(filtered);
    }
  }, [
    query,
    orderFilter,
    formattedItinerary,
    dateFilter,
    modalNameFilterSearch,
    modalItineraryFilterSearch,
    modalCategoryFilterSearch,
    modalCountryFilterSearch,
    modalCityFilterSearch,
    secondaryFilter,
    setFilteredDataLength,
  ]);

  useEffect(() => {
    setCurrentPage(0);
    setCurrentQueryNum(queryNum);
    setActivePage(0);
  }, [
    queryNum,
    query,
    orderFilter,
    formattedItinerary,
    dateFilter,
    modalNameFilterSearch,
    modalItineraryFilterSearch,
    modalCategoryFilterSearch,
    modalCountryFilterSearch,
    modalCityFilterSearch,
  ]);

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);

  if (filteredData?.length > 0) {
    const startIndex = currentPage * currentQueryNum;
    const endIndex = startIndex + currentQueryNum;
    const paginatedData = filteredData?.slice(startIndex, endIndex);

    return (
      <>
        {paginatedData?.map((row, index) => {
          return (
            <React.Fragment key={row?.id}>
              {row?.id === editRowId ? (
                <EditableRow
                  onToastOpen={onToastOpen}
                  data={row}
                  setEditId={setEditId}
                  id={index + 1}
                  categoryList={categoryData}
                />
              ) : (
                <ReadOnlyRow
                  onToastOpen={onToastOpen}
                  data={row}
                  setEditId={setEditId}
                  id={index + 1}
                  categoryList={categoryData}
                />
              )}
            </React.Fragment>
          );
        })}

        <ReactPaginate
          previousLabel={<CaretLeft size={16} />}
          nextLabel={<CaretRight size={16} />}
          breakLabel={"..."}
          pageCount={Math?.ceil(filteredData?.length / currentQueryNum)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(selectedPage) => {
            setActivePage(selectedPage?.selected);
            setCurrentPage(selectedPage?.selected);
          }}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={activePage}
        />
        {modalNameFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalNameFilterSearch}
            setModalFilterSearch={setModalNameFilterSearch}
            setModalFilter={setModalNameFilter}
            data={[
              ...new Set(formattedItinerary?.map((item) => item?.name?.trim())),
            ]?.map((item) => ({
              name: item,
            }))}
            labelPath="name"
            label="nome"
          />
        )}

        {modalItineraryFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalItineraryFilterSearch}
            setModalFilterSearch={setModalItineraryFilterSearch}
            setModalFilter={setModalItineraryFilter}
            data={[
              ...new Set(
                formattedItinerary?.map((item) => item?.itineraryCount)
              ),
            ]?.map((item) => ({ itineraryCount: String(item) }))}
            labelPath="itineraryCount"
          />
        )}

        {modalCategoryFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalCategoryFilterSearch}
            setModalFilterSearch={setModalCategoryFilterSearch}
            setModalFilter={setModalCategoryFilter}
            data={[
              ...new Set(
                formattedItinerary?.map(
                  (item) => item?.category?.names["pt-BR"]
                )
              ),
            ]?.map((item) => ({ category: item }))}
            labelPath="category"
            label="categoria"
          />
        )}

        {modalCountryFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalCountryFilterSearch}
            setModalFilterSearch={setModalCountryFilterSearch}
            setModalFilter={setModalCountryFilter}
            data={[
              ...new Set(
                formattedItinerary?.map(
                  (item) =>
                    (item?.stopsAddresses[0]?.address?.country &&
                      item?.stopsAddresses[0]?.address?.country?.trim()) ||
                    "Sem pais"
                )
              ),
            ]?.map((item) => ({ country: item }))}
            labelPath="country"
            label="pais"
          />
        )}

        {modalCityFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalCityFilterSearch}
            setModalFilterSearch={setModalCityFilterSearch}
            setModalFilter={setModalCityFilter}
            data={[
              ...new Set(
                formattedItinerary?.map(
                  (item) =>
                    (item?.stopsAddresses[0]?.address?.city &&
                      item?.stopsAddresses[0]?.address?.city?.trim()) ||
                    "Sem cidade"
                )
              ),
            ]?.map((item) => ({ city: item }))}
            labelPath="city"
            label="cidade"
          />
        )}
      </>
    );
  }
}

export const ReadOnlyRow = ({
  data: row,
  setEditId,
  id,
  categoryList,
  onToastOpen,
}) => {
  const dispatch = useDispatch();

  async function handleDelete(item) {
    try {
      await itinerary?.remove(item);
      dispatch(DeleteRow({ id: item?.id }));
      onToastOpen("Success", "success");
    } catch (error) {
      console?.error(error);
      onToastOpen("Error", "error");
    }
  }

  return (
    <TableRow key={row?.id}>
      <TableCell>{showOnlyFirstSixDigits(row?.id)}</TableCell>
      <TableCell>{row?.name}</TableCell>
      <TableCell>{dayjs(row?.createdAt)?.format("DD[/]MM[/]YYYY")}</TableCell>
      <TableCell>{row?.stopsAddresses[0]?.address?.country || "--"}</TableCell>
      <TableCell>{row?.stopsAddresses[0]?.address?.city || "--"}</TableCell>
      <TableCell x={{ width: "40px" }}>
        <TypePill
          name={row?.category?.names["pt-BR"]}
          color={row?.category?.color}
        />
      </TableCell>
      <TableCell align="right" sx={{ width: "50px" }} colSpan={0}>
        <DeleteOutline color="error" onClick={() => handleDelete(row)} />
      </TableCell>
    </TableRow>
  );
};

export const EditableRow = ({
  data: row,
  setEditId,
  id,
  categoryList,
  onToastOpen,
}) => {
  const spotCategories = categoryList
    ?.filter((category) => row?.categories?.includes(category?.id))
    ?.map((item) => item?.name);

  const [name, setName] = useState(row?.name);
  const [date, setDate] = useState(dateInputFormatter(row?.createdAt));
  const [country, setCountry] = useState(
    row?.stopsAddresses[0]?.address?.country
  );
  const [city, setCity] = useState(row?.stopsAddresses[0]?.address?.city);
  const [categories, setCategories] = useState(spotCategories);

  const dispatch = useDispatch();

  const [year, month, day] = date?.split("-");
  const formattedDate = new Date(year, month - 1, day)?.toISOString();

  const formattedCategories = categoryList
    ?.filter((category) => categories?.includes(category?.name))
    ?.map((item) => item?.id);

  const handleUpdate = async (id) => {
    try {
      await itinerary?.update({
        ...row,
        name,
        createdAt: formattedDate,
        categories: formattedCategories,
      });
      dispatch(
        UpdateRow({
          id,
          categories: formattedCategories,
          createdAt: formattedDate,
          name,
        })
      );
      setEditId(null);
      onToastOpen("Success", "success");
    } catch (error) {
      console?.error(error);
      onToastOpen("Error", "error");
    }
  };

  function handleCategoryChange(event) {
    const value = event?.target?.value;
    setCategories(typeof value === "string" ? value?.split(",") : value);
  }

  return (
    <TableRow key={row?.id}>
      <TableCell>{showOnlyFirstSixDigits(id)}</TableCell>
      <TableCell>
        <S.StyledEditInput
          type="text"
          placeholder="Atualize o Nome"
          value={name}
          onChange={(self) => setName(self?.target?.value)}
        />
      </TableCell>
      <TableCell>{dayjs(row?.createdAt)?.format("DD[/]MM[/]YYYY")}</TableCell>
      <TableCell>
        <S.StyledEditInput
          type="text"
          placeholder="Atualize o País"
          disabled
          value={country}
          onChange={(self) => setCountry(self?.target?.value)}
        />
      </TableCell>
      <TableCell>
        <S.StyledEditInput
          type="text"
          placeholder="Atualize a Cidade"
          disabled
          value={city}
          onChange={(self) => setCity(self?.target?.value)}
        />
      </TableCell>
      <TableCell>
        <Select
          variant="standard"
          value={categories}
          displayEmpty
          multiple
          onChange={handleCategoryChange}
          renderValue={() => {
            if (categories?.length === 0) {
              return <em>Selecione as categorias</em>;
            }

            return categories?.join(", ");
          }}
        >
          <MenuItem disabled value="">
            <em>Selecione as categorias</em>
          </MenuItem>
          {categoryList?.map((item, index) => (
            <MenuItem key={index} value={item?.name}>
              <S.TypeListItem>
                <S.Ball bColor={item?.color} />
                <span>{item?.name}</span>
              </S.TypeListItem>
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell align="right" sx={{ cursor: "pointer", color: "#079784" }}>
        <strong onClick={() => handleUpdate(row?.id)}>Atualizar</strong>
      </TableCell>
      <TableCell align="right" sx={{ cursor: "pointer", color: "red" }}>
        <strong onClick={() => setEditId(null)}>Cancelar</strong>
      </TableCell>
    </TableRow>
  );
};
