import { TableCell, TableRow } from "@mui/material";
import React, { useState, useEffect } from "react";
import * as S from "./styles";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAll as ConnectSelectAll,
  UpdateRow,
  UpdateValue,
} from "../../../features/ConnectpointSlice";
import { dateInputFormatter } from "../../../utils/formatter";
import TypePill from "../../../Components/TypePill";
import { getConnectpointsHistory } from "../../../Services/connectpoint";
import dayjs from "dayjs";
import { CaretLeft, CaretRight } from "phosphor-react";
import ReactPaginate from "react-paginate";
import BaseFilter from "../../../Components/BaseFilter/BaseFilter";
import { showOnlyFirstSixDigits } from "../../../utils/showOnlyFirstSixDigits";
import CurrencyFilter from "../../../Components/CurrencyFilter/CurrencyFilter";
import { getConnectGuideById } from "../../../Services/touristGuide";
import { getConnectSaleById } from "../../../Services/connectsale";

const infoByStatus = {
  direct_sales: {
    color: "#00C851",
    title: "Entrada",
  },
  connectroute_sales: {
    color: "#ff4444",
    title: "Saida",
  },
};

export default function Index({
  onToastOpen,
  secondaryFilter,
  orderFilter,
  dateFilter,
  modalCategoryFilter,
  setModalCategoryFilter,
  modalTypeFilter,
  setModalTypeFilter,
  modalCountryFilter,
  setModalCountryFilter,
  modalCityFilter,
  setModalCityFilter,
  modalStatusFilter,
  setModalStatusFilter,
  currencyFilter,
  setCurrencyFilter,
  setFilteredDataLength,
}) {
  const query = useSelector((state) => state.query.query_string);
  const queryNum = useSelector((state) => state.query.queryByNumber);
  const [editRowId, setEditId] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [formatedData, setFormatedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [currentQueryNum, setCurrentQueryNum] = useState(queryNum);
  const [activePage, setActivePage] = useState(0);
  const [modalStatusFilterSearch, setModalStatusFilterSearch] = useState([]);
  const [modalCategoryFilterSearch, setModalCategoryFilterSearch] = useState(
    []
  );
  const [modalCountryFilterSearch, setModalCountryFilterSearch] = useState([]);
  const [modalCityFilterSearch, setModalCityFilterSearch] = useState([]);
  const dispatch = useDispatch();
  const [modalTypeFilterSearch, setModalTypeFilterSearch] = useState([]);

  useEffect(() => {
    try {
      getDataFromFirebase();
    } catch (error) {
      console.error(error);
    }
  }, []);

  async function getDataFromFirebase() {
    const connectpoints = await getConnectpointsHistory();

    dispatch(UpdateValue(connectpoints));
    setData(connectpoints);
  }

  useEffect(() => {
    const formatted = data
      .map((data) => ({
        ...data,
        status: infoByStatus?.[data?.transaction_category]?.title,
      }))
      .filter((item) => {
        if (currencyFilter.value !== "all") {
          return item.purchase.currency === currencyFilter.value;
        }
        return item;
      });
    setFormatedData(formatted);
  }, [currencyFilter.value, data, query]);

  useEffect(() => {
    if (formatedData) {
      const toDate = dayjs(dateFilter?.to);
      const fromDate = dayjs(dateFilter?.from);
  
      const filtered = formatedData
        .sort((a, b) => {
          if (orderFilter && orderFilter.id === "category") {
            return orderFilter.order === "asc"
              ? b?.connectstore.category?.names?.["pt-BR"].localeCompare(
                  a?.connectstore.category?.names?.["pt-BR"]
                )
              : a?.connectstore.category?.names?.["pt-BR"].localeCompare(
                  b?.connectstore.category?.names?.["pt-BR"]
                );
          }
          if (orderFilter && orderFilter.id === "city") {
            return orderFilter.order === "asc"
              ? b?.connectstore?.address?.city.localeCompare(
                  a?.connectstore?.address?.city
                )
              : a?.connectstore?.address?.city.localeCompare(
                  b?.connectstore?.address?.city
                );
          }
  
          if (orderFilter && orderFilter.id === "country") {
            return orderFilter.order === "asc"
              ? b.connectstore?.address?.country.localeCompare(
                  a.connectstore?.address?.country
                )
              : a.connectstore?.address?.country.localeCompare(
                  b.connectstore?.address?.country
                );
          }
  
          if (orderFilter && orderFilter.id === "status") {
            return orderFilter.order === "asc"
              ? b?.status.localeCompare(a?.status)
              : a?.status.localeCompare(b?.status);
          }
  
          if (orderFilter && orderFilter.id === "value") {
            return orderFilter.order === "asc"
              ? b?.purchase.value_minor_units / 100 -
                  a?.purchase.value_minor_units / 100
              : a?.purchase.value_minor_units / 100 -
                  b?.purchase.value_minor_units / 100;
          }
          return 0;
        })
        .filter((row) => {
          let filterCategoryMatch = true;
          let filterStatusMatch = true;
          let filterCountryMatch = true;
          let filterCityMatch = true;
          let filterTypeMatch = true;
  
          if (modalStatusFilterSearch.length > 0) {
            const matchingItems = modalStatusFilterSearch.filter(
              (filterItem) =>
                filterItem.status.toLowerCase().trim() ===
                row.status.toLowerCase().trim()
            );
            filterStatusMatch = matchingItems?.length > 0;
          }
  
          if (modalCategoryFilterSearch.length > 0) {
            const matchingItems = modalCategoryFilterSearch.filter(
              (filterItem) =>
                filterItem?.category.toLowerCase().trim() ===
                row?.connectstore?.category?.names?.["pt-BR"]
                  .toLowerCase()
                  .trim()
            );
            filterCategoryMatch = matchingItems?.length > 0;
          }
  
          if (modalCountryFilterSearch.length > 0) {
            const matchingItems = modalCountryFilterSearch.filter(
              (filterItem) =>
                filterItem?.country.toLowerCase().trim() ===
                row?.connectstore?.address?.country.toLowerCase().trim()
            );
            filterCountryMatch = matchingItems?.length > 0;
          }
  
          if (modalCityFilterSearch.length > 0) {
            const matchingItems = modalCityFilterSearch.filter(
              (filterItem) =>
                filterItem?.city.toLowerCase().trim() ===
                row?.connectstore?.address?.city.toLowerCase().trim()
            );
            filterCityMatch = matchingItems.length > 0;
          }
  
          if (modalTypeFilterSearch.length > 0) {
            const type = row?.connectroute?.type === "connectguides" 
              ? "ConnectGuide" 
              : row?.connectroute?.type === "connectsales" 
                ? "ConnectSale" 
                : "ConnectStore";
                
            const matchingItems = modalTypeFilterSearch.filter(
              (filterItem) => filterItem.type.toLowerCase().trim() === type.toLowerCase().trim()
            );
            filterTypeMatch = matchingItems?.length > 0;
          }
  
          const nameMatch = row?.connectstore?.name
            ?.toLowerCase()
            .includes(query);
  
          const userIdMatch = row?.purshase?.user?.id
            ?.toLowerCase()
            .includes(query);
  
          const storeIdMatch =
            row?.connectstore?.code ??
            row?.connectstore?.id?.toLowerCase().includes(query);
  
          const routeIdMatch =
            row?.connectroute?.code ??
            row?.connectroute?.id?.toLowerCase().includes(query);
  
          const idMatch = row?.id?.toLowerCase().includes(query);
  
          const categoryMatch = row?.connectstore?.category?.names["pt-BR"]
            ?.toLowerCase()
            ?.includes(query);
  
          const connectPointsQuantityMatch = row?.connectpoints_quantity
            ?.toLowerCase()
            .includes(query);
  
          const statusMatch = row?.status?.toLowerCase().includes(query);
  
          const countryMatch = row?.connectstore?.address?.country
            ?.toLowerCase()
            .includes(query);
  
          const cityMatch = row?.connectstore?.address?.city
            ?.toLowerCase()
            .includes(query);
  
          const valueMatch = String(row?.purchase.value_minor_units / 100)
            ?.toLowerCase()
            .includes(query);
  
          return (
            (nameMatch ||
              connectPointsQuantityMatch ||
              idMatch ||
              statusMatch ||
              categoryMatch ||
              userIdMatch ||
              storeIdMatch ||
              routeIdMatch ||
              valueMatch ||
              countryMatch ||
              cityMatch) &&
            filterCategoryMatch &&
            filterStatusMatch &&
            filterCountryMatch &&
            filterCityMatch &&
            filterTypeMatch &&
            (dateFilter
              ? dayjs(row?.created_at).isAfter(fromDate) &&
                dayjs(row?.created_at).isBefore(toDate.add(1, "day"))
              : true)
          );
        });
  
      setFilteredData(filtered);
      setFilteredDataLength(filtered.length);
    }
  }, [
    data,
    query,
    secondaryFilter,
    formatedData,
    orderFilter,
    dateFilter,
    modalStatusFilterSearch,
    modalCategoryFilterSearch,
    modalCountryFilterSearch,
    modalCityFilterSearch,
    modalTypeFilterSearch,
    setFilteredDataLength,
  ]);

  useEffect(() => {
    setCurrentPage(0);
    setCurrentQueryNum(queryNum);
    setActivePage(0);
  }, [
    query,
    currencyFilter.value,
    queryNum,
    secondaryFilter,
    dateFilter,
    modalStatusFilterSearch,
    modalCategoryFilterSearch,
    modalCountryFilterSearch,
    modalCityFilterSearch,
  ]);

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);

  if (formatedData) {
    const startIndex = currentPage * currentQueryNum;
    const endIndex = startIndex + currentQueryNum;
    const paginatedData = filteredData.slice(startIndex, endIndex);

    return (
      <>
        {paginatedData.map((row) => {
          return (
            <React.Fragment key={row?.id}>
              {row.id === editRowId ? (
                <EditableRow
                  onToastOpen={onToastOpen}
                  data={row}
                  setEditId={setEditId}
                />
              ) : (
                <ReadOnlyRow
                  currencyFilter={currencyFilter}
                  onToastOpen={onToastOpen}
                  data={row}
                  setEditId={setEditId}
                />
              )}
            </React.Fragment>
          );
        })}

        <ReactPaginate
          previousLabel={<CaretLeft size={16} />}
          nextLabel={<CaretRight size={16} />}
          breakLabel={"..."}
          pageCount={Math.ceil(filteredData.length / currentQueryNum)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(selectedPage) => {
            setActivePage(selectedPage.selected);
            setCurrentPage(selectedPage.selected);
          }}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={activePage}
        />

{modalTypeFilter.isOpen && (
  <BaseFilter
    modalFilterSearch={modalTypeFilterSearch}
    setModalFilterSearch={setModalTypeFilterSearch}
    setModalFilter={setModalTypeFilter}
    data={[
      ...new Set(
        formatedData.map((item) => {
          if (item?.connectroute?.type === "connectguides") return "ConnectGuide";
          if (item?.connectroute?.type === "connectsales") return "ConnectSale";
          return "ConnectStore";
        })
      ),
    ].map((item) => ({ type: item }))}
    labelPath="type"
    label="tipo"
  />
)}

        {modalStatusFilter.isOpen && (
          <BaseFilter
            modalFilterSearch={modalStatusFilterSearch}
            setModalFilterSearch={setModalStatusFilterSearch}
            setModalFilter={setModalStatusFilter}
            data={[
              ...new Set(formatedData.map((item) => item?.status?.trim())),
            ].map((item) => ({ status: String(item) }))}
            labelPath="status"
            label="status"
          />
        )}

        {modalCategoryFilter.isOpen && (
          <BaseFilter
            modalFilterSearch={modalCategoryFilterSearch}
            setModalFilterSearch={setModalCategoryFilterSearch}
            setModalFilter={setModalCategoryFilter}
            data={[
              ...new Set(
                formatedData.map((item) =>
                  item?.connectstore?.category?.names?.["pt-BR"].trim()
                )
              ),
            ].map((item) => ({ category: item }))}
            labelPath="category"
            label="categoria"
          />
        )}

        {modalCountryFilter.isOpen && (
          <BaseFilter
            modalFilterSearch={modalCountryFilterSearch}
            setModalFilterSearch={setModalCountryFilterSearch}
            setModalFilter={setModalCountryFilter}
            data={[
              ...new Set(
                formatedData.map((item) =>
                  item?.connectstore?.address?.country.trim()
                )
              ),
            ].map((item) => ({ country: item }))}
            labelPath="country"
            label="pais"
          />
        )}

        {modalCityFilter.isOpen && (
          <BaseFilter
            modalFilterSearch={modalCityFilterSearch}
            setModalFilterSearch={setModalCityFilterSearch}
            setModalFilter={setModalCityFilter}
            data={[
              ...new Set(
                formatedData.map((item) =>
                  item?.connectstore?.address?.city.trim()
                )
              ),
            ].map((item) => ({ city: item }))}
            labelPath="city"
            label="cidade"
          />
        )}

        {currencyFilter.isOpen && (
          <CurrencyFilter
            showAll
            currencyFilter={currencyFilter}
            setCurrencyFilter={setCurrencyFilter}
          />
        )}
      </>
    );
  }
}

export const ReadOnlyRow = ({
  data: row,
  setEditId,
  onToastOpen,
  currencyFilter,
}) => {
  const [guideData, setGuideData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    if (row?.connectroute && row.connectroute.type === "connectguides") {
      const guide = await getConnectGuideById(row.connectroute.owner.id);
      setGuideData(guide);
    }
    if (row?.connectroute && row.connectroute.type === "connectsales") {
      const guide = await getConnectSaleById(row.connectroute.owner.id);
      setGuideData(guide);
    }
  }

  const currencySymbol =
    currencyFilter.value === "BRL"
      ? "R$"
      : currencyFilter.value === "USD"
      ? "$"
      : "€";

  const getDisplayData = () => {
    if (row?.connectroute?.type === "connectguides" || row?.connectroute?.type === "connectsales") {
      return {
        id: guideData?.id || "--",
        category: {
          color: guideData?.accredited ? "#1396A0" : "#01393d",
          name: guideData?.accredited ? "Credenciado" : "Não credenciado"
        }
      };
    } else if (row?.purchase?.user) {
      return {
        id: row.purchase.user.id || "--",
        category: {
          color: row.purchase.user.category?.color,
          name: row.purchase.user.category?.names["pt-BR"]
        }
      };
    } else {
      return {
        id: row?.connectstore?.code || row?.connectstore?.id || "--",
        category: {
          color: row?.connectstore?.category?.color,
          name: row?.connectstore?.category?.names["pt-BR"]
        }
      };
    }
  };

  const displayData = getDisplayData();

  return (
    <TableRow key={row?.id}>
      <TableCell>
        {showOnlyFirstSixDigits(displayData.id)}
      </TableCell>
      <TableCell>
        <TypePill
          color={displayData.category.color}
          name={displayData.category.name}
        />
      </TableCell>
      <TableCell>{dayjs(row?.created_at).format("DD[/]MM[/]YYYY")}</TableCell>
      <TableCell>
        {row?.connectroute?.type === "connectguides" 
          ? "ConnectGuide" 
          : row?.connectroute?.type === "connectsales" 
            ? "ConnectSale" 
            : "ConnectStore"}
      </TableCell>
      <TableCell>{row?.connectstore?.address?.country || "--"}</TableCell>
      <TableCell>{row?.connectstore?.address?.city || "--"}</TableCell>
      <TableCell
        style={{
          color: infoByStatus?.[row?.transaction_category]?.color,
        }}
      >
        {infoByStatus?.[row?.transaction_category]?.title || "--"}
      </TableCell>
      <TableCell>
        {currencySymbol} {(row?.purchase.value_minor_units / 100).toFixed(2)}
      </TableCell>
    </TableRow>
  );
};


export const EditableRow = ({ data: row, setEditId, onToastOpen }) => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState(row.category);
  const [date, setDate] = useState(dateInputFormatter(row.date));
  const [connectpoints, setConnectpoints] = useState(row.connectpoints);
  const [country, setCountry] = useState(row.country);
  const [city, setCity] = useState(row.city);
  const [value, setValue] = useState(row.value);
  const [status, setStatus] = useState(row.status);

  const [year, month, day] = date.split("-");
  const formattedDate = new Date(year, month - 1, day).toISOString();

  const handleUpdate = (id) => {
    dispatch(
      UpdateRow({
        id: id,
        category,
        date: formattedDate,
        connectpoints,
        country,
        city,
        value,
        status,
      })
    );
    onToastOpen("Success", "success");
    setEditId(null);
  };

  return (
    <TableRow key={row?.id}>
      <TableCell>{row?.id}</TableCell>
      <TableCell>
        <S.StyledEditInput
          type="text"
          placeholder="Filtrar por Tipo"
          value={category}
          onChange={(self) => setCategory(self.target.value)}
        />
      </TableCell>
      <TableCell>
        <S.StyledEditInput
          type="date"
          placeholder="Atualize a Data"
          value={date}
          onChange={(self) => setDate(self.target.value)}
        />
      </TableCell>
      <TableCell>
        <S.StyledEditInput
          type="text"
          placeholder="Atualize o Connectpoint"
          value={connectpoints}
          onChange={(self) => setConnectpoints(self.target.value)}
        />
      </TableCell>
      <TableCell>
        <S.StyledEditInput
          type="text"
          placeholder="Atualize o País"
          value={country}
          onChange={(self) => setCountry(self.target.value)}
        />
      </TableCell>
      <TableCell>
        <S.StyledEditInput
          type="text"
          placeholder="Atualize a Cidade"
          value={city}
          onChange={(self) => setCity(self.target.value)}
        />
      </TableCell>
      <TableCell>
        <S.StyledEditInput
          type="text"
          placeholder="Atualize o Valor"
          value={value}
          onChange={(self) => setValue(self.target.value)}
        />
      </TableCell>
      <TableCell>
        <S.StyledEditInput
          type="text"
          placeholder="Atualize a Status"
          value={status}
          onChange={(self) => setStatus(self.target.value)}
        />
      </TableCell>
      <TableCell align="right" sx={{ cursor: "pointer", color: "#079784" }}>
        <strong onClick={() => handleUpdate(row?.id)}>Atualizar</strong>
      </TableCell>
      <TableCell align="right" sx={{ cursor: "pointer", color: "red" }}>
        <strong onClick={() => setEditId(null)}>Cancelar</strong>
      </TableCell>
    </TableRow>
  );
};
