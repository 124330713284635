/****
 * In this component we declare all functionlities for TurismPointsReport Page
 * We retrieve data from our server
 */

import React, { useEffect, useState, useRef } from "react";
import BlockModal from "../../../Components/BlockModal";
import DialogModal from "../../../Components/DialogModal";
import { ShowTotal } from "../../../Components/Global/Reusable";
import StyledTable from "../../../Components/Table/index";
import { UpdateRow } from "../../../features/TuristicPointsReportSlice";
import TableData from "./bodyData";
import Details from "./Details";
import TableLabels from "./Labels";
import { AppContainer } from "../../../Components/Global/Reusable";
import ImageModal from "../../../Components/DialogModal/ImageModal";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Hooks/useAuth";
import { Toast } from "../../../Components/Toast";
import { TABLES } from "../../../enums/tables";

export default function Index() {
  const [data, setData] = useState(null);
  const [Id, setId] = useState(null);
  const [details, setDetails] = useState(false);
  const [preview, setPreview] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    title: "",
    variation: "",
  });
  const [dateFilter, setDateFilter] = useState(null);
  const [secondaryFilter, setSecondaryFilter] = useState("");
  const [filteredDataLength, setFilteredDataLength] = useState(0);
  const [orderFilter, setOrderFilter] = useState(null);
  const timerRef = useRef(0);
  const [dataQuantity, setDataQuantity] = useState(0);
  const [modalNameFilter, setModalNameFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalItineraryFilter, setModalItineraryFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalCategoryFilter, setModalCategoryFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalCountryFilter, setModalCountryFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalStateFilter, setModalStateFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [modalCityFilter, setModalCityFilter] = useState({
    isOpen: false,
    data: null,
    isUsing: null,
  });
  const [showDeleteButton, setShowDeleteButton] = useState(false); // Novo estado


  /***
   * Declare a method to Open and close Details
   * We are receiving {row.id} parameters
   */
  function toggleToast(isOpen) {
    setIsToastOpen(isOpen);
  }

  function onToastOpen(title, variation) {
    setToastMessage({ title: title, variation: variation });
    toggleToast(false);
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(function () {
      toggleToast(true);
    }, 100);
  }

  function OpenDetails(rowId) {
    setDetails(true);
    setId(rowId);
    setShowDeleteButton(true); // Exibir o botão de excluir ao abrir os detalhes
  }

  function CloseDetails() {
    setDetails(false);
    setShowDeleteButton(false); // Ocultar o botão de excluir ao fechar os detalhes
  }

  function handleDelete() {
    // Lógica para excluir o item com o ID
    console.log(`Deletar item com ID: ${Id}`);
    setShowDeleteButton(false);
    setDetails(false);
    onToastOpen("Item excluído com sucesso", "success"); // Exibir uma mensagem de sucesso
  }

  /**
   * Declare Method to Open Modal for Confirmation
   * Declare switch functionality
   */
  function OpenModal(rowIsBlocked, rowId, rowName) {
    setData({
      Id: rowId,
      active: rowIsBlocked,
      Name: rowName,
    });
  }

  const navigate = useNavigate();
  const { currentUserData, logout } = useAuth();

  useEffect(() => {
    if (currentUserData && currentUserData.role === "user") {
      (async () => {
        await logout();
        navigate("/admin/login");
      })();
    }
  }, []);

  return (
    <>
      <Toast
        variation={toastMessage.variation}
        title={toastMessage.title}
        open={isToastOpen}
        onOpenChange={toggleToast}
      />
      <AppContainer maxWidth="xl">
        <StyledTable
          TableTitle="ConnectPlaces"
          Component={<ShowTotal total={filteredDataLength} />}
          Labels={
            <TableLabels
              orderFilter={orderFilter}
              setOrderFilter={setOrderFilter}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              modalNameFilter={modalNameFilter}
              setModalNameFilter={setModalNameFilter}
              modalItineraryFilter={modalItineraryFilter}
              setModalItineraryFilter={setModalItineraryFilter}
              modalCountryFilter={modalCountryFilter}
              setModalCountryFilter={setModalCountryFilter}
              modalStateFilter={modalStateFilter}
              setModalStateFilter={setModalStateFilter}
              modalCityFilter={modalCityFilter}
              setModalCityFilter={setModalCityFilter}
            />
          }
          TableData={
            <TableData
              OpenModal={OpenModal}
              OpenDetails={OpenDetails}
              dateFilter={dateFilter}
              secondaryFilter={secondaryFilter}
              orderFilter={orderFilter}
              setDataQuantity={setDataQuantity}
              modalNameFilter={modalNameFilter}
              setModalNameFilter={setModalNameFilter}
              modalItineraryFilter={modalItineraryFilter}
              setModalItineraryFilter={setModalItineraryFilter}
              modalCountryFilter={modalCountryFilter}
              setModalCountryFilter={setModalCountryFilter}
              modalStateFilter={modalStateFilter}
              setModalStateFilter={setModalStateFilter}
              modalCityFilter={modalCityFilter}
              setModalCityFilter={setModalCityFilter}
              setFilteredDataLength={setFilteredDataLength}
            />
          }
        />
 {details && (
          <>
            <Details CloseDetails={CloseDetails} detailsID={Id} openPreview={setPreview} />

            {/* Botão de excluir */}
            {showDeleteButton && (
              <button onClick={handleDelete} className="delete-button">
                Excluir Item
              </button>
            )}
          </>
        )}

        {data && (
          <DialogModal>
            <BlockModal
              onToastOpen={onToastOpen}
              data={data}
              setModal={setData}
              UpdateRow={UpdateRow}
              role="Ponto turisticos"
              collection={TABLES.CONNECTPLACES}
            />
          </DialogModal>
        )}

        {preview && (
          <DialogModal>
            <ImageModal openPreview={setPreview} image={preview} />
          </DialogModal>
        )}
      </AppContainer>
    </>
  );
}
