import { StyledTableCell } from "./styles";
import React, { useState } from "react";
import { ArrowDown, ArrowUp } from "phosphor-react";
import DateFilter from "../../../Components/DateFilter/DateFilter";

export function ConnectpointLabel({
  orderFilter,
  setOrderFilter,
  setDateFilter,
  dateFilter,
  modalCategoryFilter,
  setModalCategoryFilter,
  modalTypeFilter,
  setModalTypeFilter,
  modalCountryFilter,
  setModalCountryFilter,
  modalCityFilter,
  setModalCityFilter,
  modalStatusFilter,
  setModalStatusFilter,
  setCurrencyFilter,
}) {
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const filtersArray = [
    modalTypeFilter,
    modalStatusFilter,
    modalCategoryFilter,
    modalCountryFilter,
    modalCityFilter,
  ];

  const columns = [
    { id: "id", label: "ID", align: "left" },
    { id: "category", label: "Categoria", align: "left" },
    { id: "date", label: "Data", align: "left" },
    { id: "type", label: "Tipo", align: "left" },
    { id: "country", label: "País", align: "left" },
    { id: "city", label: "Cidade", align: "left" },
    { id: "status", label: "Status", align: "left" },
    { id: "value", label: "Valor", align: "left" },
  ];

  function handleChangeOrder(id) {
    if (orderFilter && orderFilter.id === id) {
      const newOrder = orderFilter.order === "asc" ? "desc" : "asc";
      return setOrderFilter((prev) => ({ ...prev, order: newOrder }));
    }
    return setOrderFilter({ id: id, order: "asc" });
  }

  function openDateFilter() {
    setIsDateFilterOpen(true);
  }

  function handleOpenTypeFilter(id) {
    setModalTypeFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenStatusFilter(id) {
    setModalStatusFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenCategoryFilter(id) {
    setModalCategoryFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenCountryFilter(id) {
    setModalCountryFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenCityFilter(id) {
    setModalCityFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function openCurrencyFilter() {
    setCurrencyFilter((prev) => ({ isOpen: true, value: prev.value }));
  }

  function handleClick(id) {
    if (id === "date") return openDateFilter(id);
    if (id === "type") return handleOpenTypeFilter(id);
    if (id === "value") return openCurrencyFilter(id);
    if (id === "status") return handleOpenStatusFilter(id);
    if (id === "category") return handleOpenCategoryFilter(id);
    if (id === "country") return handleOpenCountryFilter(id);
    if (id === "city") return handleOpenCityFilter(id);
    return handleChangeOrder(id);
  }

  return (
    <>
      {columns.map((column) => {
        let isFiltered = filtersArray.some(
          (item) => item.type === column.id && item.isUsing
        );
        return (
          <StyledTableCell
            onClick={() => handleClick(column.id)}
            columnId={column.id}
            key={column.id}
            align={column.align}
          >
            <div>
              {column.label}
              {column.id !== "details" &&
                column.id !== "block" &&
                column.id !== "id" && (
                  <div>
                    <ArrowUp
                      size={16}
                      weight="regular"
                      color={`${
                        (orderFilter &&
                          orderFilter.id === column.id &&
                          orderFilter.order === "desc") ||
                        isFiltered
                          ? "#06bee7"
                          : "rgba(0, 0, 0, 0.87)"
                      }`}
                    />
                    <ArrowDown
                      color={`${
                        (orderFilter &&
                          orderFilter.id === column.id &&
                          orderFilter.order === "asc") ||
                        isFiltered
                          ? "#06bee7"
                          : "rgba(0, 0, 0, 0.87)"
                      }`}
                      size={16}
                      weight="regular"
                      style={{ marginLeft: "-4px" }}
                    />
                  </div>
                )}
            </div>
          </StyledTableCell>
        );
      })}
      {isDateFilterOpen && (
        <DateFilter
          setIsDateFilterOpen={setIsDateFilterOpen}
          setDateFilter={setDateFilter}
          dateFilter={dateFilter}
        />
      )}
    </>
  );
}
