/**
 * Login Component
 * In this component we write all the Login Funcionalities
 * We are going to include Authetication
 *
 * ********************LOGIC****************
 * As we stored in our database a key => user role
 * So we are going to use that key (user_role) to verify user role ,
 * and we are going to redirect to admin Dashboard if role is Admin else to Standard User Page
 */

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoginBanner from "../../../Assets/Images/loginBanner.webp";
import Logo from "../../../Assets/Images/logo.webp";
import { Login } from "../../../features/LoginSlice";
import {
  FormHeader,
  InputGroup,
  LinkContainer,
  LoginContainer,
  LoginLeft,
  LoginRight,
  StyledContainer,
} from "./styled";
import { Alert } from "@mui/material";
import { useAuth } from "../../../Hooks/useAuth";

export default function Index() {
  return (
    <StyledContainer>
      <LoginContainer>
        <LoginLeft>
          <img src={LoginBanner} alt="Login Banner" />
        </LoginLeft>
        <LoginRight>
          <LoginForm />
        </LoginRight>
      </LoginContainer>
    </StyledContainer>
  );
}

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { login, currentUserData } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('Dados do usuário atualizados:', currentUserData);
    
    if (currentUserData) {
      console.log('Role do usuário:', currentUserData.role);
      
      if (currentUserData.role === "admin") {
        console.log('Usuário é admin, atualizando estado e redirecionando');
        dispatch(Login({ 
          user_status: "authenticated",
          user_role: "admin"
        }));
        navigate("/admin");
      } else {
        console.log('Usuário não é admin');
        setError("Acesso negado. Apenas administradores podem acessar esta área.");
      }
    }
  }, [currentUserData, dispatch, navigate]);

  async function handleLogin(e) {
    e.preventDefault();
    
    if (!email || !password) {
      setError("Por favor, preencha todos os campos");
      return;
    }

    try {
      setError("");
      setIsLoading(true);
      
      const trimmedEmail = email.trim();
      const trimmedPassword = password.trim();
      
      console.log('Iniciando login...');
      const result = await login(trimmedEmail, trimmedPassword);
      console.log('Login realizado com sucesso:', result);
      
    } catch (err) {
      console.error('Erro no login:', err);
      if (err.message === 'auth/user-not-found') {
        setError("Usuário não encontrado");
      } else if (err.code === 'auth/wrong-password') {
        setError("Senha incorreta");
      } else {
        setError("Erro ao fazer login. Por favor, tente novamente.");
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <FormHeader>
        <img src={Logo} alt="Logo" />
      </FormHeader>

      {error && <Alert severity="error">{error}</Alert>}

      <form onSubmit={handleLogin}>
        <InputGroup>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </InputGroup>

        <InputGroup>
          <label>Senha</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </InputGroup>

        <LinkContainer>
          {/* <Link to="/forgot-password">Esqueceu a senha?</Link> */}
          <button type="submit" disabled={isLoading}>
            {isLoading ? "Entrando..." : "Entrar"}
          </button>
        </LinkContainer>
      </form>
    </>
  );
}
