/***
 * Reusable Component ShopsReport body data
 */

import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { Switch, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAll as selectCategories,
  UpdateValue as updateCategories,
} from "../../../features/CategorySlice";
import { selectAll, UpdateValue } from "../../../features/ShopsReportSlice";
import { getFirebaseDataByCreationDate } from "../../../Services/firebase";
import TypePill from "../../../Components/TypePill";
import ReactPaginate from "react-paginate";
import { CaretLeft, CaretRight } from "phosphor-react";
import { getNumberOfItinerariesWithStopId } from "../../../Services/itinerary";
import BaseFilter from "../../../Components/BaseFilter/BaseFilter";
import dayjs from "dayjs";
import { TABLES } from "../../../enums/tables";

export default function Index({
  OpenModal,
  OpenDetails,
  secondaryFilter,
  orderFilter,
  dateFilter,
  modalNameFilter,
  setModalNameFilter,
  modalItineraryFilter,
  setModalItineraryFilter,
  modalCategoryFilter,
  setModalCategoryFilter,
  modalCountryFilter,
  setModalCountryFilter,
  modalCityFilter,
  setModalCityFilter,
  setFilteredDataLength,
}) {
  const query = useSelector((state) => state?.query?.query_string);
  const queryNum = useSelector((state) => state?.query?.queryByNumber);
  const data = useSelector(selectAll);
  const allCategories = useSelector(selectCategories);
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [formatedData, setFormatedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentQueryNum, setCurrentQueryNum] = useState(queryNum);
  const [activePage, setActivePage] = useState(0);
  const [modalNameFilterSearch, setModalNameFilterSearch] = useState([]);
  const [modalItineraryFilterSearch, setModalItineraryFilterSearch] = useState(
    []
  );
  const [modalCategoryFilterSearch, setModalCategoryFilterSearch] = useState(
    []
  );
  const [modalCountryFilterSearch, setModalCountryFilterSearch] = useState([]);
  const [modalCityFilterSearch, setModalCityFilterSearch] = useState([]);

  useEffect(() => {
    async function getData() {
      try {
        await getStablishmentsDataFromFirebase();
        await getCategoriesDataFromFirebase();
      } catch (error) {
        console?.error(error);
      }
    }
    getData();
  }, []);

  async function getStablishmentsDataFromFirebase() {
    const stablishments = [];
    const stablishmentsArr = await getFirebaseDataByCreationDate(
      TABLES.CONNECTSTORES
    );

    for (const data of stablishmentsArr) {
      const size = await getNumberOfItinerariesWithStopId(data?.id);
      stablishments.push({
        ...data,
        itineraryCount: size,
      });
    }

    dispatch(UpdateValue(stablishments));
  }

  async function getCategoriesDataFromFirebase() {
    const categories = await getFirebaseDataByCreationDate("categories");
    dispatch(updateCategories(categories));
  }

  useEffect(() => {
    function formatData() {
      setFormatedData(
        data?.map((stablishment) => {
          return {
            ...stablishment,
          };
        })
      );
    }
    formatData();
  }, [data, allCategories]);

  useEffect(() => {
    if (formatedData) {
      const toDate = dayjs(dateFilter?.to);
      const fromDate = dayjs(dateFilter?.from);

      const filtered = formatedData
        ?.sort((a, b) => {
          if (orderFilter && orderFilter?.id === "name") {
            return orderFilter?.order === "asc"
              ? b?.name?.localeCompare(a?.name)
              : a?.name?.localeCompare(b?.name);
          }
          if (orderFilter && orderFilter?.id === "itineraryCount") {
            return orderFilter?.order === "asc"
              ? b?.itineraryCount - a?.itineraryCount
              : a?.itineraryCount - b?.itineraryCount;
          }
          if (orderFilter && orderFilter?.id === "city") {
            return orderFilter?.order === "asc"
              ? b?.address?.city?.localeCompare(a?.address?.city)
              : a?.address?.city?.localeCompare(b?.address?.city);
          }
          if (orderFilter && orderFilter?.id === "country") {
            return orderFilter?.order === "asc"
              ? b?.address?.country?.localeCompare(a?.address?.country)
              : a?.address?.country?.localeCompare(b?.address?.country);
          }
          if (orderFilter && orderFilter?.id === "date") {
            return orderFilter?.order === "asc"
              ? b?.createdAt?.localeCompare(a?.createdAt)
              : a?.createdAt?.localeCompare(b?.createdAt);
          }
          return 0;
        })
        ?.filter((row) => {
          let filterNameMatch = true;
          let filterItineraryMatch = true;
          let filterCategoryMatch = true;
          let filterCountryMatch = true;
          let filterCityMatch = true;

          if (modalNameFilterSearch?.length > 0) {
            const matchingItems = modalNameFilterSearch?.filter(
              (filterItem) =>
                filterItem?.name?.toLowerCase()?.trim() ===
                row?.name?.toLowerCase()?.trim()
            );
            filterNameMatch = matchingItems?.length > 0;
          }
          if (modalItineraryFilterSearch?.length > 0) {
            const matchingItems = modalItineraryFilterSearch?.filter(
              (filterItem) =>
                String(filterItem?.itineraryCount)?.toLowerCase()?.trim() ===
                String(row?.itineraryCount)?.toLowerCase()?.trim()
            );
            filterItineraryMatch = matchingItems?.length > 0;
          }
          if (modalCategoryFilterSearch?.length > 0) {
            const matchingItems = modalCategoryFilterSearch?.filter(
              (filterItem) =>
                filterItem?.category?.toLowerCase()?.trim() ===
                row?.category?.names["pt-BR"]?.toLowerCase()?.trim()
            );
            filterNameMatch = matchingItems?.length > 0;
          }
          if (modalCountryFilterSearch?.length > 0) {
            const matchingItems = modalCountryFilterSearch?.filter(
              (filterItem) =>
                filterItem?.country?.toLowerCase()?.trim() ===
                  row?.address?.country?.toLowerCase()?.trim() ||
                (filterItem?.country === "Sem pais" &&
                  row?.address?.country === "") ||
                (filterItem?.country === "Sem pais" &&
                  !row?.address?.country?.toLowerCase()?.trim())
            );
            filterCountryMatch = matchingItems?.length > 0;
          }
          if (modalCityFilterSearch?.length > 0) {
            const matchingItems = modalCityFilterSearch?.filter(
              (filterItem) =>
                filterItem?.city?.toLowerCase()?.trim() ===
                  row?.address?.city?.toLowerCase()?.trim() ||
                (filterItem?.city === "Sem cidade" &&
                  row?.address?.city === "") ||
                (filterItem?.city === "Sem cidade" &&
                  !row?.address?.city?.toLowerCase()?.trim())
            );
            filterCityMatch = matchingItems?.length > 0;
          }

          const nameMatch = row?.name?.toLowerCase()?.includes(query);
          const countryMatch = row?.address?.country
            ?.toLowerCase()
            ?.includes(query);
          const cityMatch = row?.address?.city?.toLowerCase()?.includes(query);
          const codeMatch = row?.code?.toLowerCase()?.includes(query);
          const categoryMatch = String(row?.category?.names["pt-BR"])
            ?.toLowerCase()
            ?.includes(query);

          return (
            (nameMatch ||
              codeMatch ||
              categoryMatch ||
              countryMatch ||
              cityMatch) &&
            filterNameMatch &&
            filterItineraryMatch &&
            filterCategoryMatch &&
            filterCountryMatch &&
            filterCityMatch &&
            (dateFilter
              ? dayjs(row?.createdAt)?.isAfter(fromDate) &&
                dayjs(row?.createdAt)?.isBefore(toDate?.add(1, "day"))
              : true)
          );
        });

    setFilteredDataLength(filtered.length);
    setFilteredData(filtered);
    }
  }, [
    query,
    formatedData,
    orderFilter,
    dateFilter,
    modalNameFilterSearch,
    modalItineraryFilterSearch,
    modalCategoryFilterSearch,
    modalCountryFilterSearch,
    modalCityFilterSearch,
    setFilteredDataLength,
  ]);

  useEffect(() => {
    setCurrentPage(0);
    setCurrentQueryNum(queryNum);
    setActivePage(0);
  }, [
    queryNum,
    formatedData,
    orderFilter,
    dateFilter,
    modalNameFilterSearch,
    modalItineraryFilterSearch,
    modalCategoryFilterSearch,
    modalCountryFilterSearch,
    modalCityFilterSearch,
    query,
  ]);

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);

  if (formatedData) {
    const startIndex = currentPage * currentQueryNum;
    const endIndex = startIndex + currentQueryNum;
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    return (
      <>
        {paginatedData?.map((row) => {
          return (
            <TableRow hover key={row?.id}>
              <TableCell sx={{ width: "40px" }}>{row?.code}</TableCell>
              <TableCell sx={{ width: "300px" }}>{row?.name}</TableCell>
              <TableCell>
                {dayjs(row?.created_at)?.format("DD[/]MM[/]YYYY")}
              </TableCell>

              <TableCell>{row?.itineraryCount}</TableCell>

              <TableCell x={{ width: "40px" }}>
                <TypePill
                  name={row?.category?.names["pt-BR"]}
                  color={row?.category?.color}
                />
              </TableCell>

              <TableCell>{row?.address?.country || "--"}</TableCell>
              <TableCell>{row?.address?.city || "--"}</TableCell>
              <TableCell
                sx={{ width: "70px", color: "#006875", cursor: "pointer" }}
              >
                <RemoveRedEyeOutlined onClick={() => OpenDetails(row?.id)} />
              </TableCell>
              <TableCell sx={{ width: "50px" }} align="left">
                <Switch
                  checked={!row?.active}
                  color="success"
                  onChange={() => OpenModal(!row?.active, row?.id, row?.name)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                {
                  // mostre somente os campos name e active
                  console.log(row?.name, row?.active)
                }
              </TableCell>
            </TableRow>
          );
        })}

        <ReactPaginate
          previousLabel={<CaretLeft size={16} />}
          nextLabel={<CaretRight size={16} />}
          breakLabel={"..."}
          pageCount={Math?.ceil(filteredData?.length / currentQueryNum)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(selectedPage) => {
            setActivePage(selectedPage?.selected);
            setCurrentPage(selectedPage?.selected);
          }}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={activePage}
        />

        {modalNameFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalNameFilterSearch}
            setModalFilterSearch={setModalNameFilterSearch}
            setModalFilter={setModalNameFilter}
            data={[
              ...new Set(formatedData?.map((item) => item?.name?.trim())),
            ]?.map((item) => ({
              name: item,
            }))}
            labelPath="name"
            label="nome"
          />
        )}

        {modalItineraryFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalItineraryFilterSearch}
            setModalFilterSearch={setModalItineraryFilterSearch}
            setModalFilter={setModalItineraryFilter}
            data={[
              ...new Set(formatedData?.map((item) => item?.itineraryCount)),
            ]?.map((item) => ({ itineraryCount: String(item) }))}
            labelPath="itineraryCount"
            label="quantidade de ConnectRoutes"
          />
        )}

        {modalCategoryFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalCategoryFilterSearch}
            setModalFilterSearch={setModalCategoryFilterSearch}
            setModalFilter={setModalCategoryFilter}
            data={[
              ...new Set(
                formatedData?.map((item) => item?.category?.names["pt-BR"])
              ),
            ]?.map((item) => ({ category: item }))}
            labelPath="category"
            label="categoria"
          />
        )}

        {modalCountryFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalCountryFilterSearch}
            setModalFilterSearch={setModalCountryFilterSearch}
            setModalFilter={setModalCountryFilter}
            data={[
              ...new Set(
                formatedData?.map(
                  (item) =>
                    (item?.address?.country && item?.address?.country) ||
                    "Sem pais"
                )
              ),
            ]?.map((item) => ({ country: item }))}
            labelPath="country"
            label="pais"
          />
        )}

        {modalCityFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalCityFilterSearch}
            setModalFilterSearch={setModalCityFilterSearch}
            setModalFilter={setModalCityFilter}
            data={[
              ...new Set(
                formatedData?.map(
                  (item) =>
                    (item?.address?.city && item?.address?.city) || "Sem cidade"
                )
              ),
            ]?.map((item) => ({ city: item }))}
            labelPath="city"
            label="cidade"
          />
        )}
      </>
    );
  }
}
