/***
 * Admin Navigation Menu
 */
import { MenuOutlined } from "@mui/icons-material";
import Indicators from "../../Assets/Svgs/indicators.svg";
import Financial from "../../Assets/Svgs/financial.svg";
import Configurations from "../../Assets/Svgs/configurations.svg";
import Register from "../../Assets/Svgs/register.svg";
import Reports from "../../Assets/Svgs/reports.svg";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OldLogo from "../../Assets/Images/logo02.webp";
import ConnectourLogo from "../../Assets/Svgs/connectour.svg";
import { setMenuState } from "../../features/MenuSlice";
import { useAuth } from "../../Hooks/useAuth";
import {
  LogoContainer,
  MenuContainer,
  NavContainer,
  NavSide,
  SideMenuContainer,
  SideMenuViewer,
} from "./styled";

const MenuData = [
  {
    id: 1,
    title: "Indicadores",
    icon: Indicators,
    sub: {
      id: 1,
      title: "Relatórios",
      Link: [
        {
          id: 1,
          title: "Indicadores",
          link: "/admin",
        },
      ],
    },
  },
  {
    id: 2,
    title: "Financeiro",
    icon: Financial,
    sub: {
      id: 1,
      title: "Financeiro",
      Link: [
        {
          id: 1,
          title: "Relatório Financeiro",
          link: "/admin/relatorio_financeiro",
        },
      ],
    },
  },
  {
    id: 3,
    title: "Configurações",
    icon: Configurations,
    sub: {
      id: 1,
      title: "Configurações Gerais",
      Link: [
        {
          id: 1,
          title: "Termos de Uso",
          link: "/admin/termos_de_uso",
        },
        {
          id: 2,
          title: "Denuncias",
          link: "/admin/denuncias",
        },
        // {
        //   id: 3,
        //   title: "Aprovações",
        //   link: "/admin/approved",
        // },
      ],
    },
  },
  {
    id: 4,
    title: "Cadastro",
    icon: Register,
    sub: {
      id: 1,
      title: "Cadastro",
      Link: [
        {
          id: 1,
          title: "Cadastro de Categorias",
          link: "/admin/category",
        },
        {
          id: 2,
          title: "Cadastro de Planos",
          link: "/admin/planos_de_assinatura",
        },
        {
          id: 3,
          title: "Agenda de Eventos",
          link: "/admin/agenda",
        },
        {
          id: 4,
          title: "ConnectWatch",
          link: "/admin/explorar",
        },
      ],
    },
  },
  {
    id: 5,
    title: "Relatórios",
    icon: Reports,
    sub: {
      id: 1,
      title: "Relatórios",
      Link: [
        {
          id: 2,
          title: "Relatórios de Usuários",
          link: "/admin/relatorio_de_usuario",
        },
        {
          id: 3,
          title: "Relatórios de ConnectGuide",
          link: "/admin/relatorio_de_guias_turisticas",
        },
        {
          id: 4,
          title: "Relatórios de ConnectSales",
          link: "/admin/relatorio_de_connectsales",
        },
        {
          id: 5,
          title: "Relatórios de ConnectStore",
          link: "/admin/relatorio_de_estabelecimentos",
        },
        {
          id: 6,
          title: "Relatórios de Pontos Turistícos",
          link: "/admin/relatorio_de_pontos_turisticos",
        },
        {
          id: 7,
          title: "Relatórios de ConnectPoints",
          link: "/admin/relatorio_de_connectpoints",
        },
        {
          id: 8,
          title: "Relatórios de ConnectRoutes",
          link: "/admin/relatorio_de_roteiros",
        },
        {
          id: 9,
          title: "Relatórios de Planos",
          link: "/admin/relatorio_de_assinaturas",
        },
        {
          id: 9,
          title: "Relatórios de Destaques",
          link: "/admin/relatorio_de_highlights",
        },
      ],
    },
  },
];
function Index({ setToken }) {
  //get navData
  const data = MenuData;

  //Declare dispatch and useSelector from Redux
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.menu.menuState);
  const { logout, currentUserData } = useAuth();
  const userFirstName = currentUserData
    ? currentUserData.name?.trim().split(" ")[0]
    : "";

  /****
   * Destructure AuthUser
   * Authetication
   */

  async function handleLogOut(self) {
    self.preventDefault();
    try {
      await logout();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      {
        <React.Fragment>
          <NavContainer>
            <MenuOutlined onClick={() => dispatch(setMenuState())} />
            <LogoContainer>
              <Link to={"/admin"}>
                <img
                  src={ConnectourLogo}
                  alt="official Logo"
                  style={{ cursor: "pointer" }}
                />
              </Link>
            </LogoContainer>
            <NavSide>
              <span>Olá, {userFirstName}</span>
              <button onClick={handleLogOut}>Sair</button>
            </NavSide>
          </NavContainer>
          <SideMenu data={data} menuState={menuState} />
        </React.Fragment>
      }
    </>
  );
}

export default Index;

/**
 * Sidebar component
 * In this component we are going to loop through received data a display as Side Menu Data
 *
 * We have a special funcionality which is dropDown Menu !
 * to add the Dropdown funcionality we are going to use the following logic
 *
 * ****************LOGIC****************
 * 1-We add Evenlistner to each list item
 * 2-then we declare clickedId state to hold
 * --our the clicked id in the list item where we attached an event listener
 * 3-we are going to pass the clickedId state value to viewMenu(DropDown) Child Component
 */

function SideMenu({ data, menuState }) {
  //-> Declare clickedId to hold our state
  const [ClickedId, setClickedId] = useState(null);

  //Declare dispatch and useSelector from Redux
  const dispatch = useDispatch();

  //declare a function to close menuContainer
  function handleClose(self) {
    //check if the trigged element contains a class called (menuContainer)
    try {
      const existClass = self.target.className.includes("menuContainer");
      if (existClass) {
        dispatch(setMenuState());
      }
    } catch (e) {
      return e;
    }
  }

  function isCategorySelected(item) {
    return item.sub.Link.map((item) => item.link).includes(
      window.location.pathname
    );
  }

  return (
    <>
      {menuState && (
        <MenuContainer onClick={handleClose} className="menuContainer">
          <SideMenuContainer>
            <ul>
              {data.map((item) => {
                return (
                  <li
                    key={item.id}
                    onClick={() => setClickedId(item.id)}
                    className={isCategorySelected(item) ? "selected" : ""}
                  >
                    <span>
                      <img src={item.icon} alt="" />
                    </span>
                    <span>{item.title}</span>
                  </li>
                );
              })}
            </ul>
          </SideMenuContainer>
          <DropDownMenuView
            id={ClickedId}
            data={data}
            setClickedId={setClickedId}
            handleClose={dispatch}
          />
        </MenuContainer>
      )}
    </>
  );
}
/**
 * Drop down Menu View
 *
 * in this component we receive the (clicked id & data) values/data
 * So once we received (clicked id) value and data ,
 * now we can filter the data to retrieve just a specified data based on Clicked id
 */

function DropDownMenuView({ id, data, setClickedId, handleClose }) {
  /***
   * filter the data based on the ClickedId from parent component
   * and then display the selected data
   *  */
  const SelectedMenu = data
    .filter((item) => item.id === parseInt(id))
    .map((item) => item.sub);

  return (
    <>
      {SelectedMenu &&
        SelectedMenu.map((item) => {
          return (
            <SideMenuViewer key={item.id}>
              <h4>{item.title}</h4>
              <ul>
                {item.Link.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => {
                      setClickedId(null);
                      handleClose(setMenuState());
                    }}
                  >
                    <Link to={item.link}>{item.title}</Link>
                  </li>
                ))}
              </ul>
            </SideMenuViewer>
          );
        })}
    </>
  );
}
