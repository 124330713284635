import {
  doc,
  setDoc,
  collection,
  deleteDoc,
  updateDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import axios from "axios";
import { api } from "../lib/axios";
import { TABLES } from "../enums/tables";
import { PLAN_NATURE } from "../enums/plans";

export async function createPlan(plansData) {
  const {
    names,
    collection: collectionName,
    type,
    prices_per_day,
    rules,
  } = plansData;

  const now = serverTimestamp();

  const pricesUnits = Object.keys(prices_per_day).reduce((acc, label) => {
    const price = prices_per_day[label];

    acc[label] = {
      price_minor_units: price?.price_minor_units * 100 || 0,
    };

    return acc;
  }, {});

  const prices = Object.keys(prices_per_day).reduce((acc, label) => {
    const price = prices_per_day[label];

    acc[label] = {
      price_minor_units: String(price?.price_minor_units) || 0,
    };

    return acc;
  }, {});

  let newPlan = {
    names,
    collection: collectionName,
    type,
    rules,
    prices: pricesUnits,
    created_at: now,
    collection_name: TABLES.PLANS,
    nature: PLAN_NATURE.PLAN,
    updated_at: now,
    active: true,
    frequency: {
      interval_count: 1,
      interval: "month",
    },
    highlight_screens: null,
    prices_per_day: null,
  };

  const response = await api.post("subscriptions/createStripeProduct", {
    name: `PLAN: ${collectionName} ${type} - ${names["pt-BR"]}`,
    description: `Product ${type} from ${collectionName}`,
    prices,
    frequency: collectionName === "events" ? undefined : "monthly",
  });

  const { prices: stripePrices, product } = response.data;
  for (const { price, currency } of stripePrices) {
    newPlan = {
      ...newPlan,
      stripe_product_id: product.id,
      prices: {
        ...newPlan.prices,
        [currency]: {
          ...newPlan.prices[currency],
          stripe_price_id: price.id,
        },
      },
    };
  }

  const documentRef = doc(collection(db, TABLES.PLANS));
  newPlan.id = documentRef.id;
  await setDoc(documentRef, newPlan);

  return newPlan;
}

export async function updatePlan(plansData) {
  const {
    id,
    names,
    collection: collectionName,
    prices_per_day,
    rules,
    type,
    stripe_product_id,
  } = plansData;
  const documentRef = doc(db, TABLES.PLANS, id);

  // Função para normalizar e converter valores para centavos
  const convertToCents = (value) => {
    if (typeof value === "string") {
      const normalizedValue = value.replace(",", "."); // Converte vírgula para ponto
      const parsedValue = parseFloat(normalizedValue);
      if (isNaN(parsedValue)) {
        throw new Error(`Valor inválido fornecido: ${value}`);
      }
      const cents = Math.round(parsedValue * 100); // Converte para centavos e arredonda
      if (cents > 99999999) {
        throw new Error(`Valor ${value} excede o limite máximo permitido (999999.99)`);
      }
      return cents;
    }
    if (typeof value === "number") {
      const cents = Math.round(value * 100); // Converte para centavos e arredonda
      if (cents > 99999999) {
        throw new Error(`Valor ${value} excede o limite máximo permitido (999999.99)`);
      }
      return cents;
    }
    throw new Error(`Tipo de valor não suportado: ${typeof value}`);
  };

  const pricesUnits = {};
  Object.keys(prices_per_day).forEach((label) => {
    const price = prices_per_day[label];
    if (price?.price_minor_units) {
      try {
        pricesUnits[label] = {
          price_minor_units: convertToCents(price.price_minor_units),
        };
        if (price.stripe_price_id) {
          pricesUnits[label].stripe_price_id = price.stripe_price_id;
        }
      } catch (error) {
        console.error(`Erro ao converter preço para ${label}:`, error.message);
      }
    }
  });

  // Monta objeto de preços em centavos (string), pronto para ser enviado ao Stripe.
  const prices = Object.keys(prices_per_day).reduce((acc, label) => {
    const price = prices_per_day[label];

    // Exclui preços sem stripe_price_id para moedas diferentes de BRL
    if (!price?.stripe_price_id && label !== "BRL") {
      return acc;
    }

    try {
      acc[label] = {
        price_minor_units: String(
          convertToCents(price?.price_minor_units || 0)
        ),
        stripe_price_id: price?.stripe_price_id,
      };
    } catch (error) {
      console.error(`Erro ao processar preço para ${label}:`, error.message);
    }

    return acc;
  }, {});

  const now = serverTimestamp();

  let updatedPlan = {
    id,
    names,
    type,
    prices: pricesUnits,
    rules,
    collection: collectionName,
    updated_at: now,
  };

  let response;

  try {
    if (stripe_product_id) {
      // Se o plano já existe no Stripe, vamos atualizar
      const isEvent = collectionName === "events";
      const payload = {
        name: `PLAN: ${collectionName} ${type} - ${names["pt-BR"]}`,
        description: `Produto ${type} de ${collectionName}`,
        prices: Object.keys(prices).reduce((acc, currency) => {
          // Se for evento e não tiver stripe_price_id, pula
          if (isEvent && !prices[currency].stripe_price_id) {
            return acc;
          }
          acc[currency] = {
            // NÃO fazemos /100 aqui. Já está em centavos.
            price_minor_units: String(prices[currency].price_minor_units),
            stripe_price_id: prices[currency].stripe_price_id,
          };
          return acc;
        }, {}),
        ...(isEvent ? {} : { frequency: "monthly" }),
      };

      response = await api.post(
        `subscriptions/updateStripeProduct/${stripe_product_id}`,
        payload
      );
    } else {
      // Se for um novo produto no Stripe
      response = await api.post("subscriptions/createStripeProduct", {
        name: `PLAN: ${collectionName} ${type} - ${names["pt-BR"]}`,
        description: `Produto ${type} de ${collectionName}`,
        prices,
        frequency: collectionName === "events" ? undefined : "monthly",
      });
      updatedPlan = {
        ...updatedPlan,
        stripe_product_id: response.data.product.id,
      };
    }

    // Atualiza IDs de preços retornados pelo Stripe
    if (response) {
      const { prices: stripePrices } = response.data;
      for (const { price, currency } of stripePrices) {
        updatedPlan.prices = {
          ...updatedPlan.prices,
          [currency]: {
            ...updatedPlan.prices[currency],
            stripe_price_id: price.id,
          },
        };
      }
    }

    // Salva as informações atualizadas no Firestore
    await updateDoc(documentRef, updatedPlan);
    return updatedPlan;
  } catch (error) {
    console.error("Erro ao atualizar o plano:", error.message);
    throw error;
  }
}

async function generateAuthToken() {
  try {
    const toUrlEncoded = (obj) =>
      Object.keys(obj)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(obj[k]))
        .join("&");
    const response = await axios({
      method: "post",
      url: "https://api-m.sandbox.paypal.com/v1/oauth2/token",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Basic QWFKSkFTaEhUcFY0TDJVeVlWRVBxWEdFQzZ0cjNqel9jMGxVUU15eGRKRFNJUy1XNjVOdnRzcmItYndMajh1R3pwS1FXaTQyZ19XczM5aEQ6RUJNV2kxblVaMFhYLXM3dUdIcUF5SlBaaW14UXU0bFUwcmRvOWN6MVNCeEdVZjdsdlZlLU5PVFhCQXVLTHlqR2d5WXRoTjh2bGlscV9Db2U=",
      },
      data: toUrlEncoded({ grant_type: "client_credentials" }),
    });

    return response.data.access_token;
  } catch (error) {
    console.error(error);
  }
}

async function createPaypalProduct(plansData, authToken) {
  const { type, names } = plansData;
  const product = await axios.post(
    "https://api-m.sandbox.paypal.com/v1/catalogs/products",
    {
      name: names["pt-BR"],
      description: `Produto ${type.plan} de ${type.value}`,
      type: "SERVICE",
      category: "TOURIST_ATTRACTIONS_AND_EXHIBITS",
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return product.data.id;
}

async function createPaypalSubscription(
  plansData,
  productId,
  currency,
  value,
  authToken
) {
  const { type, names } = plansData;
  const plan = await axios.post(
    "https://api-m.sandbox.paypal.com/v1/billing/plans",
    {
      product_id: productId,
      name: names["pt-BR"],
      status: "ACTIVE",
      description: `Plano ${type.plan} de ${type.value}`,
      billing_cycles: [
        {
          tenure_type: "REGULAR",
          sequence: 1,
          total_cycles: 0,
          pricing_scheme: {
            fixed_price: {
              currency_code: currency,
              value: value,
            },
          },
          frequency: {
            interval_unit: "MONTH",
            interval_count: 1,
          },
        },
      ],
      quantity_supported: false,
      payment_preferences: {
        auto_bill_outstanding: true,
        setup_fee_failure_action: "CONTINUE",
        payment_failure_threshold: 1,
        setup_fee: {
          currency_code: currency,
          value: "0.0",
        },
      },
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );

  return plan.data.id;
}

async function updatePaypalSubscription(planId, newPrice, currency, authToken) {
  try {
    const pricingSchemes = [
      {
        billing_cycle_sequence: 1,
        pricing_scheme: {
          fixed_price: {
            value: newPrice,
            currency_code: currency,
          },
        },
      },
    ];

    const response = await axios.post(
      `https://api-m.sandbox.paypal.com/v1/billing/plans/${planId}/update-pricing-schemes`,
      { pricing_schemes: pricingSchemes },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to update plan");
    }
  } catch (error) {
    console.error(error);
  }
}

export async function deletePlan(plansData) {
  const { id, prices, stripe_product_id: stripeProductId } = plansData;

  const authToken = await generateAuthToken();

  for (const price of Object.values(prices)) {
    const { paypal_subscription_id, stripe_price_id } = price;
    if (paypal_subscription_id) {
      await deactivatePaypalSubscription(paypal_subscription_id, authToken);
    }
    if (stripe_price_id) {
      await api.delete(`subscriptions/deleteStripePrice/${stripe_price_id}`);
    }
  }
  if (stripeProductId) {
    await api.delete(`subscriptions/deleteStripeProduct/${stripeProductId}`);
  }
  if (prices.BRL.mercadopagoPlanId) {
    await api.delete(
      `subscriptions/cancelMercadopagoPlan/${prices.BRL.mercadopagoPlanId}`
    );
  }

  await updateDoc(doc(db, TABLES.PLANS, id), {
    active: false,
  });
}

export async function deletePlanWithoutSubscription(plansData) {
  const { id, prices, stripeProductId } = plansData;

  const authToken = await generateAuthToken();

  for (const price of Object.values(prices)) {
    const { paypalSubscriptionId, stripePriceId } = price;
    await deactivatePaypalSubscription(paypalSubscriptionId, authToken);
    await api.delete(`subscriptions/deleteStripePrice/${stripePriceId}`);
  }
  await api.delete(`subscriptions/deleteStripeProduct/${stripeProductId}`);

  await updateDoc(doc(db, TABLES.PLANS, id), {
    active: false,
  });
}

async function deactivatePaypalSubscription(planId, authToken) {
  try {
    const response = await axios.post(
      `https://api-m.sandbox.paypal.com/v1/billing/plans/${planId}/deactivate`,
      {},
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 204) {
      throw new Error("Failed to deactivate plan");
    }
  } catch (error) {
    console.error(error);
  }
}
