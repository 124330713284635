import { makeStyles } from "@mui/material/styles";
import { ArrowBack } from "@mui/icons-material";
import { Autocomplete, Box, LinearProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileInputVideo from "../../../Assets/Svgs/IconVideo.svg";
import FileInputImage from "../../../Assets/fileInputImage.svg";
import { InputGroup } from "../../../Components/Global/Reusable";
import { useCountriesAndCities } from "../../../Hooks/useCountriesAndCities";
import { createExplore, updateExplore } from "../../../Services/explore";
import {
  CreateInstitutionalVideo,
  UpdateRow,
  selectAll,
} from "../../../features/exploreSlice";
import * as S from "./styles";
import { useAuth } from "../../../Hooks/useAuth";
import { LoadingSpinner } from "../../../Components/Loader/index";

export function Register({ editId, closeRegister, onToastOpen }) {
  const [isLoading, setIsLoading] = useState(true);
  const { cities, countries, regions } = useCountriesAndCities();

  useEffect(() => {
    if (cities.length > 0 && countries.length > 0 && regions.length > 0) {
      setIsLoading(false);
    }
  }, [cities, countries, regions]);

  const ExploreRegisterState = useSelector(
    (state) => state.menu.ExploreRegisterState
  );
  const [itemToEdit] = useSelector(selectAll).filter(
    (item) => item.id === editId
  );

  return (
    <>
      {ExploreRegisterState && (
        <S.OverlayContainer>
          <S.RegisterContainer>
            {isLoading && (
              <div
                style={{
                  display: "flex !important",
                  width: "100%",
                  height: "100%",
                  left: "0px",
                  position: "absolute",
                  backgroundColor: "rgb(255, 255, 255, 0.7)",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: "50",
                }}
              >
                <div
                  style={{
                    flexWrap: "wrap",
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <LoadingSpinner />
                </div>
              </div>
            )}
            <S.RegisterHeader>
              <ArrowBack onClick={closeRegister} />
              <h3>Video Institucional</h3>
            </S.RegisterHeader>
            <S.RegisterBody>
              <CreateForm
                cities={cities}
                countries={countries}
                regions={regions}
                onToastOpen={onToastOpen}
                itemToEdit={itemToEdit}
                closeRegister={closeRegister}
              />
            </S.RegisterBody>
          </S.RegisterContainer>
        </S.OverlayContainer>
      )}
    </>
  );
}

function CreateForm({
  closeRegister,
  itemToEdit,
  onToastOpen,
  cities,
  countries,
  regions,
}) {
  const dispatch = useDispatch();

  const [file, setFile] = useState("");
  const [progress, setProgress] = useState(false);
  const [modifyUrl, setModifyUrl] = useState(true);
  const [image, setImage] = useState([]);

  const [name, setName] = useState(() => {
    if (itemToEdit && itemToEdit.name) {
      return itemToEdit.name;
    }
    return "";
  });
  const [link, setLink] = useState(() => {
    if (itemToEdit && itemToEdit?.video_url) {
      setModifyUrl(false);
      return itemToEdit?.video_url;
    }
    return "";
  });
  const [country, setCountry] = useState(() => {
    if (itemToEdit && itemToEdit.country) {
      return itemToEdit.country;
    }
    return "";
  });

  const [owner, setOwner] = useState(() => {
    if (itemToEdit && itemToEdit.owner) {
      return itemToEdit.owner;
    }
    return "";
  });

  const [region, setRegion] = useState(() => {
    if (itemToEdit && itemToEdit.region) {
      return itemToEdit.region;
    }
    return "";
  });
  const [city, setCity] = useState(() => {
    if (itemToEdit && itemToEdit.city) {
      return itemToEdit.city;
    }
    return "";
  });
  const [imageUrl, setImageUrl] = useState(() => {
    if (itemToEdit && itemToEdit.image_url) {
      return itemToEdit.image_url;
    }
    return "";
  });
  const { currentUser, currentUserData } = useAuth();

  const filteredRegions = regions.filter(
    (city) => city.country_name === country
  );

  useEffect(() => {
    if (!itemToEdit && currentUser && currentUserData) {
      setOwner(currentUser.uid);
    }
  }, [currentUser, currentUserData]);

  const filteredCities = cities.filter((city) => city.state_name === region);

  const data = {
    video_url: modifyUrl ? file : link,
    country,
    city,
    name,
    owner,
    region,
    image_url: image ? image : itemToEdit.image_url,
  };
  const isCorrectForm =
    !!name && !!country && (!!image || !!imageUrl) && (!!link || !!file);

  const handleSubmit = async (self) => {
    self.preventDefault();
    setProgress(true);

    const isUrlNotValid =
      typeof link === "string" &&
      !(
        link.includes("tiktok") ||
        link.includes("youtube") ||
        link.includes("youtu.be") ||
        link.includes("facebook") ||
        link.includes("firebasestorage") ||
        link.includes("fb.watch")
      );

    if (!file && isUrlNotValid) {
      onToastOpen("Não é possivel cadastrar videos desse site", "error");
      setProgress(false);
      return;
    }

    if (itemToEdit) {
      const imagesData =
        data.image_url instanceof File ? data.image_url : itemToEdit.image_url;

      try {
        const updatedData = await updateExplore({
          ...itemToEdit,
          ...data,
          image_url: imagesData,
        });

        dispatch(UpdateRow(updatedData));
        onToastOpen("Success", "success");
        closeRegister();
      } catch (error) {
        setProgress(false);
        console.error(error);
        onToastOpen("Error", "error");
      } finally {
        setProgress(false);
      }
      return;
    }

    try {
      const createdData = await createExplore(data);
      dispatch(CreateInstitutionalVideo(createdData));
      onToastOpen("Success", "success");
      closeRegister();
      setProgress(false);
    } catch (error) {
      setProgress(false);
      console.error(error);
      onToastOpen("Error", "error");
    }
  };

  function handleVideoChange(event) {
    event.preventDefault();
    if (event.target.files.length === 0) return;
    const file = event.target.files[0];
    setFile(file);
  }

  function handleImageChange(event) {
    event.preventDefault();
    if (event.target.files.length === 0) return;
    const file = event.target.files[0];
    setImage(file);
    setImageUrl(URL.createObjectURL(file));
  }

  function handleClickModifyUrl() {
    setModifyUrl(!modifyUrl);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <S.InputGroup>
          <label htmlFor="name">Nome</label>
          <input
            type="text"
            required
            value={name}
            onChange={(self) => setName(self.target.value)}
          />
        </S.InputGroup>
        <InputGroup>
          <label htmlFor="country">País</label>
          <Autocomplete
            id="country"
            options={countries.map((country) => country.name)}
            style={{ width: 300 }}
            value={country}
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
            onChange={(_, newInputValue) => {
              setCountry(newInputValue);
              setRegion("");
              setCity("");
            }}
          />
        </InputGroup>
        <InputGroup>
          <label htmlFor="region">Região</label>
          <Autocomplete
            id="região"
            options={filteredRegions.map((item) => item.name)}
            style={{ width: 300 }}
            value={region}
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
            onChange={(_, newValue) => {
              setRegion(newValue);
              setCity("");
            }}
          />
        </InputGroup>

        <InputGroup>
          <label htmlFor="city">Cidade</label>
          <Autocomplete
            id="city"
            options={filteredCities.map((item) => item.name)}
            style={{ width: 300 }}
            value={city}
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
            onChange={(_, newValue) => {
              setCity(newValue);
            }}
          />
        </InputGroup>
        <S.FileInputWrapper file={imageUrl}>
          <Box>
            <img
              src={imageUrl ? imageUrl : FileInputImage}
              alt="fileInputImage"
            />
            <input
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleImageChange}
            />
          </Box>
        </S.FileInputWrapper>
        {modifyUrl ? (
          <S.FileInputWrapper file="">
            <Box>
              <img src={FileInputVideo} alt="" />
              <input
                type="file"
                accept="video/mp4, video/x-m4v, video/*"
                onChange={handleVideoChange}
              />
            </Box>
            {file && <p>{file.name}</p>}
          </S.FileInputWrapper>
        ) : (
          <S.InputGroup>
            <label htmlFor="name">Link</label>
            <input
              type="text"
              required
              value={link}
              onChange={(self) => setLink(self.target.value)}
            />
          </S.InputGroup>
        )}
      </Box>
      <S.RegisterButton type="button" onClick={handleClickModifyUrl}>
        {modifyUrl ? "Link do Video" : "Upload do Vídeo"}
      </S.RegisterButton>
      <S.RegisterButton disabled={!isCorrectForm || progress} type="submit">
        {itemToEdit ? "Salvar" : "Cadastrar"}
      </S.RegisterButton>
      {progress && <LinearProgress />}
    </form>
  );
}
