/****
 * In this component we Declare Labels for the table
 * So I decided to separate these components to make our code more reusable
 */
import { ArrowDown, ArrowUp } from "phosphor-react";
import React, { useState } from "react";
import DateFilter from "../../../Components/DateFilter/DateFilter";
import { StyledTableCell } from "./styled";

export default function Index({
  orderFilter,
  setOrderFilter,
  setDateFilter,
  dateFilter,
  modalNameFilter,
  setModalNameFilter,
  modalTypeFilter,
  setModalTypeFilter,
  modalModelFilter,
  setModalModelFilter,
  currencyFilter,
  setCurrencyFilter,
  modalActiveFilter,
  setModalActiveFilter,
}) {
  const Labels = [
    {
      id: "signatureId",
      label: "ID da assinatura",
      minWidth: 100,
      align: "left",
    },
    { id: "status", label: "Status", minWidth: 100 },

    { id: "name", label: "Usuário", minWidth: 60, align: "center" },
    { id: "type", label: "Tipo do plano", minWidth: 100, align: "center" },
    { id: "model", label: "Nome do plano", minWidth: 110, align: "center" },
    {
      id: "date",
      label: "Data",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "price",
      label: `Preço (${currencyFilter.value})`,
      minWidth: 100,
      align: "left",
    },
  ];

  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const filtersArray = [modalNameFilter, modalTypeFilter, modalModelFilter, modalActiveFilter];

  function handleChangeOrder(id) {
    if (orderFilter && orderFilter.id === id) {
      const newOrder = orderFilter.order === "asc" ? "desc" : "asc";
      return setOrderFilter((prev) => ({ ...prev, order: newOrder }));
    }
    return setOrderFilter({ id: id, order: "asc" });
  }

  function handleOpenNameFilter(id) {
    setModalNameFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenTypeFilter(id) {
    setModalTypeFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function handleOpenModelFilter(id) {
    setModalModelFilter((prev) => ({ ...prev, isOpen: true, type: id }));
  }

  function openCurrencyFilter() {
    setCurrencyFilter((prev) => ({ isOpen: true, value: prev.value }));
  }

  function handleOpenActiveFilter(id) {
    const type = id === 'active' ? 'Ativo' : 'Inativo';
    setModalActiveFilter((prev) => ({ ...prev, isOpen: true, type }));
  }

  function handleClick(id) {
    if (id === "date") return openDateFilter(id);
    if (id === "name") return handleOpenNameFilter(id);
    if (id === "type") return handleOpenTypeFilter(id);
    if (id === "model") return handleOpenModelFilter(id);
    if (id === "price") return openCurrencyFilter();
    if (id === "status") return handleOpenActiveFilter(id);

    return;
  }

  function openDateFilter() {
    setIsDateFilterOpen(true);
  }
  const LabelItems = () => (
    <>
      {Labels.map((column) => {
        let isFiltered = filtersArray.some(
          (item) => item.type === column.id && item.isUsing
        );
        return (
          <StyledTableCell
            columnId={column.id}
            key={column.id}
            align={column.align}
          >
            <div>
              <div
                style={{
                  minWidth: `${column.minWidth}px`,
                  textAlign: column?.align,
                }}
                onClick={() => handleClick(column.id)}
              >
                {column.label}
              </div>
              {column.id !== "edit" &&
                column.id !== "delete" &&
                column.id !== "id" &&
                column.id !== "date" &&
                column.id !== "signatureId" &&
                column.id !== "status" &&
                column.id !== "category" && (
                  <div
                    onClick={() => handleChangeOrder(column.id)}
                    className="!w-[60px]"
                  >
                    <ArrowUp
                      size={16}
                      weight="regular"
                      color={`${
                        (orderFilter &&
                          orderFilter.id === column.id &&
                          orderFilter.order === "desc") ||
                        isFiltered
                          ? "#06bee7"
                          : "rgba(0, 0, 0, 0.87)"
                      }`}
                    />
                    <ArrowDown
                      color={`${
                        (orderFilter &&
                          orderFilter.id === column.id &&
                          orderFilter.order === "asc") ||
                        isFiltered
                          ? "#06bee7"
                          : "rgba(0, 0, 0, 0.87)"
                      }`}
                      size={16}
                      weight="regular"
                      style={{ marginLeft: "-4px" }}
                    />
                  </div>
                )}
            </div>
          </StyledTableCell>
        );
      })}
    </>
  );

  return (
    <>
      <LabelItems />
      <div>
        {isDateFilterOpen && (
          <DateFilter
            setIsDateFilterOpen={setIsDateFilterOpen}
            setDateFilter={setDateFilter}
            dateFilter={dateFilter}
          />
        )}
      </div>
    </>
  );
}
