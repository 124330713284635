import { TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { CaretLeft, CaretRight } from "phosphor-react";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import BaseFilter from "../../../Components/BaseFilter/BaseFilter";
import CurrencyFilter from "../../../Components/CurrencyFilter/CurrencyFilter";
import { TABLES } from "../../../enums/tables";
import { getFirebaseDataByCreationDate } from "../../../Services/firebase";
import { showOnlyFirstSixDigits } from "../../../utils/showOnlyFirstSixDigits";

// Função helper para converter status
const convertStatus = (status) => {
  switch (status?.toLowerCase()) {
    case 'approved':
      return 'Ativo';
    case 'canceled':
      return 'Cancelado';
    case 'pending':
      return 'Pendente';
    default:
      return 'Inativo';
  }
};

// 1. Primeiro, mover a função getCurrencySymbol para o topo do componente
const getCurrencySymbol = (currency) => {
  switch(currency) {
    case "BRL":
      return "R$";
    case "USD":
      return "$";
    case "EUR":
      return "€";
    default:
      return "R$";
  }
};

export default function Index({
  orderFilter,
  dateFilter,
  setPurchaseData,
  modalNameFilter,
  setModalNameFilter,
  modalTypeFilter,
  setModalTypeFilter,
  modalModelFilter,
  setModalModelFilter,
  currencyFilter,
  setCurrencyFilter,
  modalActiveFilter,
  setModalActiveFilter,
  setFilteredDataLength,
}) {
  const [purchases, setPurchases] = useState([]);
  const [data, setData] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const monthToFilter = Number(queryParams?.get("month"));

  const query = useSelector((state) => state?.query?.query_string);
  const queryNum = useSelector((state) => state?.query?.queryByNumber);

  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentQueryNum, setCurrentQueryNum] = useState(queryNum);
  const [activePage, setActivePage] = useState(0);
  const [modalNameFilterSearch, setModalNameFilterSearch] = useState([]);
  const [modalTypeFilterSearch, setModalTypeFilterSearch] = useState([]);
  const [modalModelFilterSearch, setModalModelFilterSearch] = useState([]);
  const [modalActiveFilterSearch, setModalActiveFilterSearch] = useState([]);
  const [allPurchases, setAllPurchases] = useState([]); // Novo estado para armazenar todos os dados

  // 3. Atualizar a formatação dos dados no getDataFromFirebase
  async function getDataFromFirebase() {
    try {
      const dataPurchases = await getFirebaseDataByCreationDate(TABLES.PURCHASES);
      
      // Formatar os dados antes de armazenar
      const formattedPurchases = dataPurchases.map(purchase => ({
        id: purchase.id,
        status: convertStatus(purchase.status),
        name: purchase.user?.name || '--',
        type: currentPurchaseType[purchase.plan?.collection] || '--', 
        planType: currentPurchaseTypePlan[purchase.plan?.type] || '--',
        amount: purchase.amount_minor_units 
          ? `${getCurrencySymbol(currencyFilter.value)} ${(purchase.amount_minor_units / 100).toFixed(2)}`
          : '--',
        created_at: purchase.created_at,
        currency: purchase.currency,
        active: purchase.active
      }));

      setPurchases(formattedPurchases);
      setAllPurchases(formattedPurchases); // Armazena todos os dados formatados
      setFilteredDataLength(formattedPurchases.length); // Atualiza o contador total
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  }

  // 4. Adicionar useEffect para atualizar quando o filtro de moeda mudar
  useEffect(() => {
    getDataFromFirebase();
  }, [currencyFilter.value]);

  const currentPurchaseType = {
    connectguides: "ConnectGuide",
    connectstores: "ConnectStore",
    connectsales: "ConnectSale",
  };

  const currentPurchaseTypePlan = {
    complete: "ConnectStore completo",
    basic: "ConnectStore básico",
    "amateur-guide": "ConnectGuide não credenciado",
    "pro-guide": "ConnectGuide credenciado",
    connectsale: "ConnectSale",
  };

  useEffect(() => {
    async function formatTableData() {
      if (!purchases) return;
      let thisPurchases = purchases;

      if (monthToFilter !== 0) {
        thisPurchases = filterMonth(thisPurchases, monthToFilter);
      }

      const currentData = thisPurchases?.filter(
        (purchase) =>
          purchase?.status === "approved" &&
          purchase?.plan &&
          purchase?.plan?.collection !== "events" &&
          purchase?.plan?.nature !== "highlight" &&
          purchase?.plan?.collection !== "connectwatches"
      );
      setData(currentData);
    }
    formatTableData();
  }, [purchases, monthToFilter, currencyFilter.value]);

  function filterMonth(array, month) {
    return array?.filter((item) => {
      const date = new Date(item?.created_at);
      const itemMonth = date?.getMonth() + 1;

      return itemMonth === month;
    });
  }

  useEffect(() => {
    if (allPurchases.length > 0) {
      const toDate = dayjs(dateFilter?.to);
      const fromDate = dayjs(dateFilter?.from);

      const filtered = allPurchases.filter(row => {
        // Adiciona filtro por moeda
        const currencyMatch = row.currency === currencyFilter.value;
        
        const nameMatch = row.name.toLowerCase().includes(query?.toLowerCase() || '');
        const typeMatch = row.type.toLowerCase().includes(query?.toLowerCase() || '');
        const planMatch = row.planType.toLowerCase().includes(query?.toLowerCase() || '');
        const statusMatch = row.status.toLowerCase().includes(query?.toLowerCase() || '');
        const dateMatch = dateFilter 
          ? dayjs(row.created_at).isAfter(fromDate) && 
            dayjs(row.created_at).isBefore(toDate?.add(1, 'day'))
          : true;

        // Inclui currencyMatch na condição de filtro
        return currencyMatch && (nameMatch || typeMatch || planMatch || statusMatch) && dateMatch;
      });

      setFilteredData(filtered);
      setFilteredDataLength(filtered.length);
    }
  }, [query, allPurchases, dateFilter, orderFilter, currencyFilter]); // Adiciona currencyFilter como dependência

  useEffect(() => {
    setCurrentPage(0);
    setCurrentQueryNum(queryNum);
    setActivePage(0);
  }, [
    queryNum,
    query,
    filteredData,
    dateFilter,
    modalModelFilterSearch,
    modalNameFilterSearch,
    modalActiveFilterSearch,
    modalTypeFilterSearch,
    orderFilter,
  ]);

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);
  const statusEnum = {
    approved: "Ativo",
    pending: "Pendente",
    cancelled: "Cancelado",
  };

  if (filteredData) {
    const startIndex = currentPage * currentQueryNum;
    const endIndex = startIndex + currentQueryNum;
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    setPurchaseData(data);    

    return (
      <>
        {paginatedData?.map((row) => (
          <TableRow hover key={row.id}>
            <TableCell>{showOnlyFirstSixDigits(row.id)}</TableCell>
            <TableCell>{row.status}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.type}</TableCell>
            <TableCell>{row.planType}</TableCell>
            <TableCell>{dayjs(row.created_at).format('DD/MM/YYYY')}</TableCell>
            <TableCell>{row.amount}</TableCell>
          </TableRow>
        ))}
        
        <ReactPaginate
          previousLabel={<CaretLeft size={16} />}
          nextLabel={<CaretRight size={16} />}
          breakLabel={"..."}
          pageCount={Math.ceil(filteredData?.length / currentQueryNum)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={({ selected }) => setCurrentPage(selected)}
          forcePage={activePage}
          className="pagination"
        />

        {modalNameFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalNameFilterSearch}
            setModalFilterSearch={setModalNameFilterSearch}
            setModalFilter={setModalNameFilter}
            data={[
              ...new Set(
                data?.map(
                  (item) => item?.user?.name && item?.user?.name?.trim()
                )
              ),
            ]?.map((item) => ({ name: item }))}
            labelPath="name"
            label="nome"
          />
        )}

        {modalTypeFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalTypeFilterSearch}
            setModalFilterSearch={setModalTypeFilterSearch}
            setModalFilter={setModalTypeFilter}
            data={[
              ...new Set(
                data?.map((item) =>
                  currentPurchaseType?.[item?.plan?.collection]?.trim()
                )
              ),
            ]?.map((item) => ({ type: String(item) }))}
            labelPath="type"
            label="tipo do plano"
          />
        )}

        {modalModelFilter?.isOpen && (
          <BaseFilter
            modalFilterSearch={modalModelFilterSearch}
            setModalFilterSearch={setModalModelFilterSearch}
            setModalFilter={setModalModelFilter}
            data={[
              ...new Set(
                data?.map((item) =>
                  currentPurchaseTypePlan?.[item?.plan.type]?.trim()
                )
              ),
            ]?.map((item) => ({ model: item }))}
            labelPath="model"
            label="modelo do plano"
          />
        )}

        {currencyFilter.isOpen && (
          <CurrencyFilter
            currencyFilter={currencyFilter}
            setCurrencyFilter={setCurrencyFilter}
          />
        )}
        {modalActiveFilter.isOpen && (
          <BaseFilter
            modalFilterSearch={modalActiveFilterSearch}
            setModalFilterSearch={setModalActiveFilterSearch}
            setModalFilter={setModalActiveFilter}
            data={[
              { status: "approved", label: "Ativo" },
              { status: "pending", label: "Pendente" },
              { status: "cancelled", label: "Inativo" },
            ]}
            labelPath="label"
            label="status"
          />
        )}
      </>
    );
  }
  return (
    <>
      <p style={{ paddingLeft: "1rem", whiteSpace: "nowrap" }}>
        Nenhum resultado?.
      </p>
    </>
  );
}
